import React, { useState } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import KeyIcon from '@mui/icons-material/Key';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { useNotify, useTranslate } from 'react-admin';
import { useLocation, Link } from 'react-router-dom';
import { entrypoint } from "../config";

const theme = createTheme({
    palette: {
        primary: {
            main: '#38a9b4',
        },
    },
});

const Background = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundImage: 'radial-gradient(circle at 50% 14em, #90dfe7 0%, #288690 60%, #288690 100%)',
});

const LoginCard = styled(Card)({
    width: '300px',
    margin: 'auto',

    marginTop: '9%',
    textAlign: 'center',
    padding: '16px',
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '4px',
    boxShadow:' rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
    overflow: 'hidden',
});
const StyledCardContent = styled(CardContent)({
    padding:0,
    marginBottom:19,
    
    
});


const StyledAvatar = styled(Avatar)({
    backgroundColor: 'rgb(40, 134, 144)',
    
});

const StyledTextField = styled(TextField)({
    width: '100%', 
    '& .MuiInputLabel-root': {
        color: 'rgba(0, 0, 0, 0.6)',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: '400',
        fontSize: '1rem',
        lineHeight: '1.4375em',
        letterSpacing: '0.00938em',
        whiteSpace: 'nowrap',
        transform: 'translate(12px, 4px) scale(0.75)'
    },
    
    '& .MuiInputBase-input.MuiFilledInput-input': {
        height: '1.4375em',
        padding: '21px 12px 4px',
        letterSpacing: 'inherit',
    },

    '& .MuiFilledInput-underline:before': {
       
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        left: '0px',
        bottom: '0px',
        position: 'absolute',
        right: '0px',
        transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        pointerEvents: 'none',
    },
    '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: `2px solid ${theme.palette.primary.main}` 
    },
    '& .MuiFilledInput-underline.Mui-focused:before': {
        borderBottom: `2px solid ${theme.palette.primary.main}` 
    },
    '& .MuiFilledInput-root': {
        backgroundColor: '#fff',
        border: '1px solid #d1d3e2',
        borderRadius: '4px'
    },
    
    margin: '1em 0',
});

const LoginFormButton = styled(Button)({
    marginTop: '20px',
    color: '#ffffff',
});

const ResetPasswordForm = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const defaultEmail = params.get('email') || '';
    const [email, setEmail] = useState(defaultEmail);
    const notify = useNotify();
    const translate = useTranslate();

    const handleResetPassword = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!email) {
            notify(translate('ra.validation.required'));
            return;
        }
    
        fetch(`${entrypoint}/reset-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email: email })
        })
        .then(response => {
            if (response.status === 200) {
                notify("Un lien de réinitialisation a été envoyé à votre adresse e-mail.");
            } else {
                notify("Erreur lors de l'envoi de l'e-mail de réinitialisation. Veuillez réessayer.");
            }
        });
    };
    

    return (
        <ThemeProvider theme={theme}>
            <Background>
                <div className="MuiScopedCssBaseline-root">
                    <div className="css-1rujn38-RaLogin-root">
                        <LoginCard>
                            <div className='RaLogin-avatar'>
                                <StyledAvatar>
                                    <KeyIcon />
                                </StyledAvatar>
                            </div>
                            <form noValidate onSubmit={handleResetPassword}>
                                <StyledCardContent>
                                    <StyledTextField
                                        label={"Email"}
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        fullWidth
                                        variant="filled"
                                        required
                                    />
                                    
                                    <LoginFormButton
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                    >
                                        {"Envoyer"}
                                    </LoginFormButton>
        
                                </StyledCardContent>
                            </form>
                        </LoginCard>
                    </div>
                </div>
            </Background>
        </ThemeProvider>
    );
}

export default ResetPasswordForm;
