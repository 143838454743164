import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TextField } from '@mui/material';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { entrypoint } from "../../config/index.js";

function ComplianceTable({ role, onUpdateData }) {
    const [actions, setActions] = useState([]);
    const token = localStorage.getItem('token');
    const handleActionChange = (index, field, value) => {
        const updatedActions = [...actions];
        updatedActions[index][field] = value;
        setActions(updatedActions);
        console.log(actions);
        // Supprime la propriété 'action' de chaque objet dans le tableau
        const dataToSend = updatedActions.map(({ action, ...rest }) => rest);
        console.log(dataToSend);
        onUpdateData(dataToSend);
    };
    useEffect(() => {
        fetch(`${entrypoint}/brandcompliance/compliance-form-action/${role}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => setActions(data.actions))
        .catch(error => console.error('There was a problem with the fetch operation:', error.message));
    }, [role, token]);
    const documentProofs = [
        "Présentation du registre d'étang consultation de l'espace adhérent",
        "Entretien visite avec l'exploitant"
    ];
    const mergeRules = [
        { start: 0, count: 5 },
        { start: 5, count: 11 }
    ];

    const getRowSpan = (index) => {
        for (let rule of mergeRules) {
            if (index === rule.start) return rule.count;
            if (index > rule.start && index < rule.start + rule.count) return 0;
        }
        return 1;
    };
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [currentActionIndex, setCurrentActionIndex] = useState(null);

    const handleOpenDialog = (index, content) => {
        setDialogContent(content);
        setCurrentActionIndex(index);
        setOpenDialog(true);
    };

    const handleValidateAndClose = () => {
        if (currentActionIndex !== null) {
            handleActionChange(currentActionIndex, 'improvement', dialogContent);
        }
        setOpenDialog(false);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    let proofIndex = 0; 
    return (
        <>
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            PaperProps={{ style: { minWidth: '300px' } }} 
        >
            <DialogTitle>{"Contenu"}</DialogTitle>
            <DialogContent>
                <TextField 
                    value={dialogContent}
                    onChange={(e) => setDialogContent(e.target.value)}
                    fullWidth 
                    multiline
                    variant="outlined"
                    rows={4}
                    InputProps={{
                        inputProps: {
                            style: {
                                resize: 'both',
                                overflow: 'auto',
                                maxHeight: '400px'
                            }
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleValidateAndClose} color="primary">
                    Valider
                </Button>
            </DialogActions>
        </Dialog>

        <Table className="compliance-table">
            <TableHead>
                <TableRow>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '500px' }}>Actions</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '150px' }}>Documents preuve</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold' }}>Conforme: O/N</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '250px' }}>Points d'amélioration</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '250px' }}>Actions à conduire</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {actions.map((action, index) => {
                    const rowSpan = getRowSpan(index);
                    let proofText = '';
                    if (rowSpan > 0) {
                        proofText = documentProofs[proofIndex] || '';
                        proofIndex++;
                    }
                    return (
                        <TableRow key={index}>
                            <TableCell>{action.action}</TableCell>
                            { 
                                rowSpan > 0 && 
                                <TableCell rowSpan={rowSpan} style={{ textAlign: 'center' }}>
                                    {proofText}
                                </TableCell>
                            } 
                            <TableCell style={{ textAlign: 'center' }}>
                                <Checkbox color="primary" checked={action.conforme === true} onChange={(e) => handleActionChange(index, 'conforme', e.target.checked)}/>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TextField 
                                    placeholder="Entrer les points d'amélioration" 
                                    variant="outlined" 
                                    size="small" 
                                    fullWidth 
                                    style={{ fontSize: '12px' }}
                                    value={action.improvement}
                                    onChange={(e) => handleActionChange(index, 'improvement', e.target.value)}
                                    InputProps={{ 
                                        style: { fontSize: '12px' },
                                        endAdornment: (
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleOpenDialog(index, action.improvement)}
                                            >
                                                <VisibilityIcon fontSize="small" />
                                            </IconButton>
                                        )
                                    }} 
                                />
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TextField 
                                    placeholder="Entrer les actions à conduire" 
                                    variant="outlined" 
                                    size="small" 
                                    fullWidth 
                                    style={{ fontSize: '12px' }}
                                    value={action.totake}
                                    onChange={(e) => handleActionChange(index, 'actiontotake', e.target.value)}
                                    InputProps={{ 
                                        style: { fontSize: '12px' },
                                        endAdornment: (
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleOpenDialog(index, action.totake)}
                                            >
                                                <VisibilityIcon fontSize="small" />
                                            </IconButton>
                                        )
                                    }} 
                                />
                            </TableCell>

                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
        </>
    );
}

export default ComplianceTable;
