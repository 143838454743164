// in src/posts.js
import {
    useRecordContext,
    useRefresh,
    useNotify,
    useRedirect,
    CreateButton,
    TopToolbar,
    ExportButton,
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    EditButton,
    Button,
    Link,
    useGetOne,
    useListController,
    ReferenceField,
    FunctionField,
    useDataProvider, 
    ReferenceInput, 
    SelectInput, 
    FilterForm,


} from 'react-admin';
import React, { useState, useEffect } from 'react';
import {Box, Typography} from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'
import {orderExporter, orderExporterPDF} from '../Tools/ExportExcel/exportAerator';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';

const Empty = () => {
    let data = useListController();
    return(
    <Box textAlign="center" m={12}>
        <Typography variant="h4" paragraph>
            Pas encore de Aérateur.
        </Typography>
        <Typography variant="body1">
            Voulez-vous en créer un ?
        </Typography>
        <Button href={"#/action_aerators/create?id="+data.filterValues.waterBody  } label="Créer"/>
    </Box>
);
}

const EmptyTextField = ({ source }) => {
    const record = useRecordContext();
    console.log(record);
    if (record[source] === undefined) {
        return (<span style={{color:'red'}}>Non saisi</span>);
    } else {
        return (<span>{record && record[source]}</span>);
    }

};
const dateRenderer = (record) => {
  const startDate = moment(record.startDate).format('YYYY');
  const endDate = moment(record.endDate).format('YYYY');
  return `${startDate} / ${endDate}`;
};

const PostListActions = (waterName) => {
    let data = useListController();
    const dataProvider = useDataProvider();
    const [campaignValue, setCampaignValue] = useState(null); // valeur par défaut

    const handleSelectChange = (event) => {
      setCampaignValue(event.target.value);
      
    };
    let waterNameExport = waterName?.waterName;
    if (waterNameExport) {
    waterNameExport = waterNameExport.replace(/[*?:\/[\]]/g, '_'); 
    }
  let exportFileName = 'Aérateur - ' + (waterNameExport || '');
    const postFilters = [
      <ReferenceInput source="campaign" label="Campagne" reference="campaigns" alwaysOn>
          <SelectInput 
              emptyText="Toutes les campagnes" 
              optionText={dateRenderer} 
              label="Campagne" 
              fullWidth  
              defaultValue="TOUS" 
              onChange={handleSelectChange}
          />
      </ReferenceInput>
    ];
   return (
    <TopToolbar>
        <Stack direction="row" justifyContent="flex-end"   alignItems="center">

            <span style={{display: 'contents'}} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFilters} /></span>
            <div style={{display: 'flex'}}>
            <Button href={"#/action_aerators/create?id="+data.filterValues.waterBody  } label="Créer"/>
            <div style={{marginLeft:10}}>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#38a9b4', height: 30, width: 'auto', border: 'none', margin: '0 20px 0 0', display:'flex', alignItems:'center' }}>
                <span style={{ marginRight: 10 }}>Exporter</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu" style={{ padding: 10, border: '1px solid #f2f2f2', width:200 }}>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <ExportButton label='Exporter (Excel)' onClick={() => orderExporter(data.data, null, dataProvider, campaignValue, exportFileName)}/>
                    <ExportButton label='Exporter (PDF)' onClick={() => orderExporterPDF(data.data, null, dataProvider, campaignValue, exportFileName)}/>
                    </div>
                </Dropdown.Menu>
                </Dropdown>
                </div>
            </div>
        </Stack>
    </TopToolbar>
);
}


const RenderField = ({date, source}) => {
    const record = useRecordContext();
    let dateRecord = '';

    if (date === 'start') {
        dateRecord = `${moment(record.startDate).format('DD/MM/YYYY')} ${moment(record.startTime).zone("+02:00").format('HH:mm')}`;
    }

    if (date === 'end') {
        dateRecord = `${moment(record.endDate).format('DD/MM/YYYY')} ${moment(record.endTime).zone("+02:00").format('HH:mm')}`;
    }

    return (<span style={{color:'black'}}>{dateRecord}</span>);
};

const AeratorList = () => {
    useEffect(() => {
        const headers = document.querySelectorAll('.MuiTableRow-root.MuiTableRow-head .MuiTableCell-root > span');
        
        headers.forEach(header => {
        if (header.textContent === 'MODIFIER') {
            header.classList.add('hide-label');
        }
        });
    }, []);  
    let data = useListController();
    const waterBody = useGetOne('water_bodies', { id: data.filterValues.waterBody });
    moment.locale('fr');

    return (
        <List actions={<PostListActions waterName={waterBody?.data?.name}/>} exporter={false} empty={<Empty />}>
            <h1 className={"titleName"}>{data !== undefined ? data.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
            <Datagrid>
                <TextField source="originId" label="#"/>
                <RenderField source="startDate" label="Date début" date={'start'}/>
                <RenderField source="endDate" label="Date fin" date={'end'}/>
                {/* <FunctionField
                    label="Date début"
                    render={record => (<>{moment(record.startDate).format('DD/MM/YYYY')}<br />{moment(record.startTime).zone("+02:00").format('HH:mm')}</>)}
                />;
                <FunctionField
                    label="Date fin"
                    render={record => (<>{moment(record.endDate).format('DD/MM/YYYY')}<br />{moment(record.endTime).zone("+02:00").format('HH:mm')}</>)}
                />; */}
                <ReferenceField link={false} label="Aérateur" source="aerator" reference="aerators">
                    <FunctionField render={record => record && `${record.name}`} />
                </ReferenceField>
                <TextField source="power" label="Puissance (en Kw)"/>
                <EmptyTextField source="electricalCost" label="Coût du Kw.h (en € HT)"/>
                <FunctionField
                  label="Coût électricité estimé (en € HT)"
                  render={record => {
                      const startDate =  moment(record.startDate);
                      const startTime = moment(record.startTime);
                      const endDate = moment(record.endDate);
                      const endTime = moment(record.endTime);
                      let nbHours = startTime.diff(endTime, 'minutes');
                      const nbDays = endDate.diff(startDate, 'minutes');
                      nbHours = (nbHours/60).toFixed(0);
                    console.log('NB HOURS:' + nbHours);
                    console.log('NB DAYS:' + nbDays/60/24);
                    if (Math.sign(nbHours) == -1) {
                    nbHours = -nbHours;
                    } else {
                      nbHours = 24-nbHours;
                  }
                    // return `${((endMoment.diff(startMoment, 'minutes')+1440)/60*record.power*record.electricalCost).toFixed(2)}`;
                    const calculatedValue = (((nbHours * nbDays / 60 / 24) * record.power) * record.electricalCost).toFixed(2);

                    // Vérification des valeurs NaN, null, undefined, etc.
                    if (!calculatedValue || isNaN(calculatedValue) || calculatedValue === null || calculatedValue === undefined) {
                        return <span style={{ color: 'red' }}>Non saisi</span>;
                    }
                    return calculatedValue;

                  }}
                />
                <EditButton label="MODIFIER"/>
            </Datagrid>
        </List>
    );
}
export default AeratorList;
