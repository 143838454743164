import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-admin';
import "./backbutton.css";


const BackButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };

        return (
            
        <Button label="Retour" id="btnRetour" onClick={handleClick} style={{width:150}}/>
        );
    

};


export default BackButton;