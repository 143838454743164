import * as React from 'react';
import { Create, useListController,ReferenceArrayInput,AutocompleteArrayInput, useGetOne,useRedirect, useGetList, SimpleForm,DateTimeInput, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { RichTextInput } from 'ra-input-rich-text';

const RegulatedAnimalCreate = () => {
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 5 },
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };
    });
  }
  const queryParameters = new URLSearchParams(window.location.hash.split('?')[1])
  const id = queryParameters.get("id")
  const waterBody = useGetOne('water_bodies', {id: id});
  const onSuccess = () => {
    redirect({
      pathname: '/action_regulated_animals',
      search: `filter=${JSON.stringify({ waterBody: id })}`,
    });
  };
  let current = useListController();
  return (
      <Create mutationOptions={{ onSuccess }} mutationMode="pessimistic">
        <SimpleForm sanitizeEmptyValues>
          <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />

            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} >
              <DateInput source="date" label="Date" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput source="regulatedAnimal" reference="regulated_animals" label="Animal">
                <SelectInput label="Animal" optionText="name"  validate={[required()]} fullWidth/>
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="killedNumber" label="Nombre d'animaux observés" fullWidth />
            </Grid>
            <Grid item xs={3} className={"itemForm2"}>
              <NumberInput source="firedNumber" label="Nombre animaux tirés" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="trappedNumber" label="Nombre animaux piégés" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
          <Grid item xs={6}>
            <TextInput source="note" label="Remarque(s)" multiline fullWidth />
          </Grid>
        </Grid>

          {waterBody.data !== undefined ? <TextInput source="waterBody" className={"hidden"} defaultValue={waterBody.data.id} type={"hidden"}/> : ''}


        </SimpleForm>
      </Create>
  );
}

export default RegulatedAnimalCreate;
