import React, { useState, useEffect, useRef } from "react";
import {
  useDataProvider,
  usePermissions,
  SelectInput,
  useGetOne,
  ReferenceInput,
  AutocompleteArrayInput,
  Form,
  useGetList,
  FormDataConsumer,
  useGetIdentity,
  BooleanInput,
  useNotify,
} from "react-admin";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import "moment/locale/fr";
import { entrypoint } from "../config/index.js";
import { useNavigate } from "react-router-dom";

const MultiRequester = () => {
  const [filter, setFilter] = useState(null);
  const [choicesWaterBody, setChoicesWaterBody] = useState([]);
  const [choicesBasinBody, setChoicesBasinBody] = useState([]);
  const [filterUser, setFilterUser] = useState(null);
  const [waterBodyData, setWaterBodyData] = useState(null);
  const [userChoices, setUserChoices] = useState([]);
  const [productChoices, setProductChoices] = useState([]);
  const [productFilter, setProductFilter] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showRegulationFilters, setShowRegulationFilters] = useState(false);
  const [animalChoices, setAnimalChoices] = useState([]);
  const [specieStageChoices, setSpecieStageChoices] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectAllCampaigns, setSelectAllCampaigns] = useState(false);
  const [showCoutPompageCampaign, setShowCoutPompageCampaign] =
    useState(false);
  const [showTempsPecheCampaign, setShowTempsPecheCampaign] = useState(false);
  const [showTravauxEntretienCampaign, setShowTravauxEntretienCampaign] =
    useState(false);
  const [showExtraireDonneesRegulationsCampaign, setShowExtraireDonneesRegulationsCampaign] = useState(false);
  const [showCoutFertilizationCampaign, setShowCoutFertilizationCampaign] = useState(false);
  const [showPrixChaulageCampaign, setShowPrixChaulageCampaign] =
    useState(false);
  const [showProtectionsCormoransCampaign, setShowProtectionsCormoransCampaign] = useState(false);
  const [showDatesAerationCampaign, setShowDatesAerationCampaign] =
    useState(false);
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const notify = useNotify();
  const hasRun = useRef(false);
  const navigate = useNavigate();

  // Load form data from local storage on component mount
  useEffect(() => {
    const loadFormData = () => {
        const savedData = localStorage.getItem("multiRequesterFormData");
        if (savedData) {
            setFormData(JSON.parse(savedData));
        }
    };

    loadFormData();
    
    fetchWaterBodies({ waterBodyType: 1 }).then(setChoicesWaterBody); // Fetch étangs (type 1)
    fetchWaterBodies({ waterBodyType: 2 }).then(setChoicesBasinBody); // Fetch bassins (type 2)

    const cachedUsers = loadCachedData("cachedUsers");
    if (cachedUsers) {
        setUserChoices(cachedUsers);
    } else if (permissions && permissions?.includes("ROLE_ADMIN")) {
        prepareUserChoices().then(setUserChoices);
    }
}, [permissions]);


  const loadCachedData = (key) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      return JSON.parse(cachedData);
    }
    return null;
  };

  const saveCachedData = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleFilterChangeUser = async (event) => {
    const selectedUserId = event.target.value
        ? parseInt(event.target.value.split("/users/")[1])
        : null;
    setFilterUser(selectedUserId);
    setFilter(null);

    if (selectedUserId) {
        const waterBodiesList = await fetchWaterBodies({ user: selectedUserId, waterBodyType: 1 });
        setChoicesWaterBody(waterBodiesList);
        const basinBodiesList = await fetchWaterBodies({ user: selectedUserId, waterBodyType: 2 });
        setChoicesBasinBody(basinBodiesList);
    } else {
        fetchWaterBodies({ waterBodyType: 1 }).then(setChoicesWaterBody);
        fetchWaterBodies({ waterBodyType: 2 }).then(setChoicesBasinBody);
    }
};


  const fetchWaterBodies = async (filter = {}) => {
    const cachedData = loadCachedData("cachedWaterBodies");
    if (cachedData && !filter.user) {
      return cachedData;
    }

    const waterBodiesList = [];
    let page = 1;
    let perPage = 1000;
    let fetchedAll = false;
    console.log(filter)
    while (!fetchedAll) {
      const { data, total } = await dataProvider.getList("water_bodies", {
        sort: { field: "id", order: "ASC" },
        pagination: { page, perPage },
        filter,
      });

      waterBodiesList.push(...data);

      if (waterBodiesList?.length >= total) {
        fetchedAll = true;
      } else {
        page++;
      }
    }

    saveCachedData("cachedWaterBodies", waterBodiesList);
    return waterBodiesList;
  };

  const fetchAllWaterBodies = async (waterbodytype) => {
    const waterBodiesList = await fetchWaterBodies({ waterbodytype });
    return waterBodiesList;
  };

  useEffect(() => {
    const fetchWaterBodyData = async () => {
      if (filter) {
        try {
          const response = await dataProvider.getOne("water_bodies", {
            id: filter,
          });
          setWaterBodyData(response.data);
        } catch (error) {
          console.error(error);
          setWaterBodyData(null);
        }
      } else {
        setWaterBodyData(null);
      }
    };
    fetchWaterBodyData();
  }, [filter, dataProvider]);

  useEffect(() => {
    if (!filterUser) {
      fetchAllWaterBodies(1).then(setChoicesWaterBody);
      fetchAllWaterBodies(2).then(setChoicesBasinBody);
    }
  }, [filterUser]);

  const onlyUser = async (userId) => {
    const waterBodiesList = await fetchWaterBodies({ user: userId, waterBodyType: 1 });
    setChoicesWaterBody(waterBodiesList);
    const basinBodiesList = await fetchWaterBodies({ user: userId, waterBodyType: 2 });
    setChoicesBasinBody(basinBodiesList);
  };

  useEffect(() => {
    if (
      permissions !== undefined &&
      !permissions?.includes("ROLE_ADMIN") &&
      !hasRun.current
    ) {
      let onlyUserId = `/users/${identity.id}`;
      onlyUser(onlyUserId);
      hasRun.current = true;
    } else if (
      permissions !== undefined &&
      permissions?.includes("ROLE_ADMIN") &&
      !hasRun.current
    ) {
      fetchAllWaterBodies(1).then(setChoicesWaterBody);
      fetchAllWaterBodies(2).then(setChoicesBasinBody);
      hasRun.current = true;
    }
  }, [permissions, identity, dataProvider]);

  const prepareUserChoices = async () => {
    const { data: users } = await dataProvider.getList("users", {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "lastName", order: "ASC" },
    });
    const userChoicesData = users.map((user) => ({
      id: user.id,
      name: `${user.firstName} ${user.lastName} ${
        user.structureName ? "/ " + user.structureName : ""
      }`,
    }));
    saveCachedData("cachedUsers", userChoicesData);
    return userChoicesData;
  };

  const { data: campaignChoices } = useGetList("campaigns", {
    sort: { field: "startDate", order: "ASC" },
    pagination: { page: 1, perPage: 100 },
  });

  let sortedCampaignChoices = [];
  if (campaignChoices && Array.isArray(campaignChoices)) {
    sortedCampaignChoices = [...campaignChoices].sort((a, b) =>
      moment.utc(a.startDate).diff(moment.utc(b.startDate))
    );
  }
  const campaignChoicesCustom = sortedCampaignChoices
    ? sortedCampaignChoices.map((campaign) => {
        moment.locale("fr");
        const startDate = moment(campaign.startDate).format("YYYY");
        const endDate = moment(campaign.endDate).format("YYYY");
        return {
          id: campaign.id,
          name: startDate + " / " + endDate,
        };
      })
    : [];

  const rawDataOptions = [
    { id: "empoissonnement_kg_ha", name: "Empoissonnage kg/ha" },
    { id: "marges_brutes_ha", name: "Marges brutes /ha" },
    { id: "rendements_net_en_kg_ha", name: "Rendements net en kg/ha" },
    {
      id: "coefficient_d_accroissement_globaux",
      name: "Coefficient d'accroissement globaux",
    },
    { id: "rendement_brut_global", name: "Rendement brut globaux" },
    {
      id: "coefficient_d_accroissement_moyen",
      name: "Coefficient d'accroissement moyen",
    },
    { id: "coefficient_multiplicateur", name: "Coefficient multiplicateur" },
    { id: "prix_moyen_de_vente", name: "Prix moyen de vente" },
  ];

  const fetchProductChoices = async (type) => {
    const { data } = await dataProvider.getList(type, {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "name", order: "ASC" },
    });
    return data.map((product) => ({
      id: product.id,
      name: product.name,
    }));
  };

  useEffect(() => {
    if (productFilter) {
      fetchProductChoices(productFilter).then(setProductChoices);
    } else {
      setProductChoices([]);
    }
  }, [productFilter]);

  const speciesOptions = useGetList("specie_fishes/uniq", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  }).data;

  const speciesChoices = speciesOptions
    ? speciesOptions.map((species) => ({
        id: species.id,
        name: species.name,
      }))
    : [];

  const fetchRegulatedAnimals = async () => {
    const { data } = await dataProvider.getList("regulated_animals", {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "name", order: "ASC" },
    });
    return data.map((animal) => ({
      id: animal.id,
      name: animal.name,
    }));
  };

  useEffect(() => {
    fetchRegulatedAnimals().then(setAnimalChoices);
    prepareSpecieFishChoices(dataProvider).then(setSpecieStageChoices);
  }, [dataProvider]);

  const handleSubmit = async (data) => {
    console.log(data); // Log form data to console

    // Save form data to localStorage
    localStorage.setItem("multiRequesterFormData", JSON.stringify(data));

    // Retrieve formData from localStorage and parse it
    const formDataToSubmit = JSON.parse(localStorage.getItem("multiRequesterFormData")) || {};
    const token = localStorage.getItem("token");
    const extractUserId = (user) => {
      if (typeof user === 'string' && user.includes('/users/')) {
        return user.split('/users/')[1];
      }
      return user;
    };
  
    const formattedData = {
      // Ensure the user field contains only the user ID
      user: extractUserId(filterUser || formDataToSubmit.user || null),
      waterBodies: formDataToSubmit.waterBodies || [],
      basinBodies: formDataToSubmit.basinBodies || [],
      campaigns: formDataToSubmit.campaigns || [],
      indicators: formDataToSubmit.rawData || [],
      speciesStage: formDataToSubmit.specieFish || null,
      speciesCategory: formDataToSubmit.specieName || null,
      actionType: formDataToSubmit.rawCategory || null,
      product: formDataToSubmit.products || null,
      gestionAssec: formDataToSubmit.gestionAssec || false,
      protectionsCormorans: formDataToSubmit.protectionsCormorans || false,
      datesAeration: formDataToSubmit.datesAeration || false,
      coutPompage: formDataToSubmit.coutPompage || false,
      tempsPeche: formDataToSubmit.tempsPeche || false,
      travauxEntretien: formDataToSubmit.travauxEntretien || false,
      extraireDonneesRegulations: formDataToSubmit.extraireDonneesRegulations || false,
      coutFertilization: formDataToSubmit.coutFertilization || false,
      prixChaulage: formDataToSubmit.prixChaulage || false,
      animal: formDataToSubmit.animal || null,
      startDate: formDataToSubmit.startDate || null,
      endDate: formDataToSubmit.endDate || null,
      nbrVus: formDataToSubmit.nbrVus || null,
      nbrShoot: formDataToSubmit.nbrShoot || null,
      nbrTrapped: formDataToSubmit.nbrTrapped || null,
    };

    console.log("Formatted Data to submit:", formattedData);

    try {
      const response = await fetch(`${entrypoint}/multi-request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, 
        },
        body: JSON.stringify(formattedData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("Response:", responseData); 
      notify("Form submitted successfully", { type: "info" });
      navigate("/multi_requester/view", { state: { data: responseData.data } });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      notify("Error submitting the form", { type: "warning" });
    }
  };

  const prepareSpecieFishChoices = async (dataProvider) => {
    const { data: species } = await dataProvider.getList("specie_fishes", {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "name", order: "ASC" },
    });

    const uniqueSpeciesMap = new Map();
    species.forEach((specie) => {
      if (!uniqueSpeciesMap.has(specie.name)) {
        uniqueSpeciesMap.set(specie.name, specie);
      }
    });

    return Array.from(uniqueSpeciesMap.values()).map((specie) => ({
      id: specie.name,
      name: specie.name,
    }));
  };

  const formatSpecieFish = (record) => {
    if (!record.stage) {
      record.stage = "Stade non connu";
    }
    return record.stage;
  };

  function GetNameSpieces(id) {
    const  spieciesName = useGetOne('specie_fishes', { id: id });
    if(spieciesName.data)  {
      return spieciesName.data.name;
    } else {
      return spieciesName.data;
    }
  }
  

  const handleSelectAllCampaigns = () => {
    if (selectAllCampaigns) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        campaigns: [],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        campaigns: campaignChoicesCustom.map((campaign) => campaign.id),
      }));
    }
    setSelectAllCampaigns(!selectAllCampaigns);
  };
  const clearCache = () => {
    setFormData({});
    localStorage.removeItem("multiRequesterFormData");
    window.location.reload();
  };
  useEffect(() => {
    if (
      campaignChoicesCustom?.length > 0 &&
      formData?.campaigns?.length === campaignChoicesCustom?.length
    ) {
      setSelectAllCampaigns(true);
    } else {
      setSelectAllCampaigns(false);
    }
  }, [formData.campaigns, campaignChoicesCustom]);

  return (
    <div className="background-container container-fluid">
      <div className="chart-container d-flex flex-column">
        <p
          className="bodyTitle text-left mt-3 mb-4"
          style={{ paddingLeft: "15px" }}
        >
          Multi-requêteur
        </p>
      </div>
      <Form
        className="multirequester-container container"
        onSubmit={handleSubmit}
        record={formData}
      >
        <button
          type="button"
          className="btn btn-secondary"
          style={{
            backgroundColor: "#4e73df",
            border: "none",
            marginBottom: 10,
          }}
          onClick={clearCache}
        >
          Réinitialiser la sélection
        </button>
        <div className="multirequester-filter mb-4 p-3 border rounded">
          <h5 className="mb-4">
            <FontAwesomeIcon icon={faFilter} className="filter-icon" />
            Indicateurs technico-économiques
          </h5>
          <div className="row">
            {permissions !== undefined &&
              permissions?.includes("ROLE_ADMIN") && (
                <div className="col-md-6 mb-3">
                  <SelectInput
                    className="autocomplete-input"
                    source="user"
                    choices={userChoices}
                    optionValue="id"
                    optionText="name"
                    emptyText="Tous les utilisateurs"
                    label="Nom/Prénom/Organisme"
                    onChange={handleFilterChangeUser}
                    defaultValue={formData.user}
                    fullWidth
                    alwaysOn
                  />
                </div>
              )}
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                  {({ formData, ...rest }) => (
                      <SelectInput
                          className="autocomplete-input"
                          optionText="name"
                          source="waterBodies"
                          label="Étang"
                          fullWidth
                          defaultValue={formData.waterBodies}
                          choices={choicesWaterBody}  // Étangs uniquement
                          onChange={handleFilterChange}
                          {...rest}
                      />
                  )}
              </FormDataConsumer>
          </div>
          <div className="col-md-6 mb-3">
              <FormDataConsumer>
                  {({ formData, ...rest }) => (
                      <SelectInput
                          className="autocomplete-input"
                          optionText="name"
                          source="basinBodies"
                          label="Bassin"
                          fullWidth
                          defaultValue={formData.basinBodies}
                          choices={choicesBasinBody}  // Bassins uniquement
                          onChange={handleFilterChange}
                          {...rest}
                      />
                  )}
              </FormDataConsumer>
          </div>

            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="campaigns"
                    choices={campaignChoicesCustom}
                    className="autocomplete-input"
                    optionText="name"
                    label="Campagne"
                    defaultValue={formData.campaigns}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
              <p
                style={{
                  color: selectAllCampaigns ? "#D32F2F" : "#4e73df",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handleSelectAllCampaigns}
              >
                {selectAllCampaigns ? "Désélectionner tout" : "Sélectionner tout"}
              </p>

            </div>
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="rawData"
                    choices={rawDataOptions}
                    className="autocomplete-input"
                    optionText="name"
                    label="Indicateurs"
                    defaultValue={formData.rawData}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          </div>
        </div>
        <div className="multirequester-filter mb-4 p-3 border rounded">
          <h5 className="mb-4">
            <FontAwesomeIcon icon={faFilter} className="filter-icon" />
            Étang(s)
          </h5>
          <div className="row multirequester-filter" style={{ margin: 0 }}>
            <div className="col-md-6">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    className="autocomplete-input"
                    optionValue="id"
                    optionText="name"
                    source="specieName"
                    label="Espèce"
                    fullWidth
                    choices={speciesChoices}
                    defaultValue={formData.specieName}
                    onChange={(e) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        specieName: e.target.value,
                        specieFish: null,
                      }))
                    }
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
            <div className="col-md-6">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <ReferenceInput
                    key={formData.specieName} // Utilisez une clé unique basée sur l'espèce sélectionnée
                    source="specieFish"
                    reference={
                      "specie_fishes?name=" + GetNameSpieces(formData.specieName)
                    }
                    filter={{ nonemptystage: true }}
                    filterToQuery={(searchText) => ({ stage: searchText })}
                    alwaysOn
                  >
                    <SelectInput
                      optionText={formatSpecieFish}
                      label="Stade Espèce"
                      fullWidth
                      defaultValue={formData.specieFish}
                    />
                  </ReferenceInput>
                )}
              </FormDataConsumer>
            </div>
            <div className="col-md-6">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    source="rawCategory"
                    choices={[
                      { id: "limings", name: "Chaulage" },
                      { id: "food", name: "Alimentation" },
                      { id: "fertilizations", name: "Fertilisation" },
                    ]}
                    className="autocomplete-input"
                    optionText="name"
                    label="Charges"
                    fullWidth
                    defaultValue={formData.rawCategory}
                    onChange={(e) => setProductFilter(e.target.value)}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
            <div className="col-md-6">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    className="autocomplete-input"
                    optionText="name"
                    source="products"
                    label="Produits"
                    fullWidth
                    defaultValue={formData.products}
                    choices={productChoices}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          </div>
        </div>
        <div className="boolean-filter mb-2 p-3 border rounded">
          <h5 className="mb-4">
            <FontAwesomeIcon icon={faFilter} className="filter-icon" /> Filtres
            supplémentaires
          </h5>
          <BooleanInput
            source="gestionAssec"
            label="Filtrer les étangs pour lequel le formulaire Gestion de l'assec a été renseigné."
            defaultValue={formData.gestionAssec}
          />
          <BooleanInput
            source="protectionsCormorans"
            label="Extraire les types et nombres de protections cormorans en place"
            defaultValue={formData.protectionsCormorans}
            onChange={(e) =>
              setShowProtectionsCormoransCampaign(e.target.checked)
            }
          />
          {showProtectionsCormoransCampaign && (
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="protectionsCormoransCampaign"
                    choices={campaignChoicesCustom}
                    className="autocomplete-input"
                    optionText="name"
                    label="Campagne pour protections cormorans"
                    defaultValue={formData.protectionsCormoransCampaign}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          )}
          <BooleanInput
            source="datesAeration"
            label="Extraire les dates et plages horaires aération"
            defaultValue={formData.datesAeration}
            onChange={(e) => setShowDatesAerationCampaign(e.target.checked)}
          />
          {showDatesAerationCampaign && (
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="datesAerationCampaign"
                    choices={campaignChoicesCustom}
                    className="autocomplete-input"
                    optionText="name"
                    label="Campagne pour dates aération"
                    defaultValue={formData.datesAerationCampaign}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          )}
          <BooleanInput
            source="coutPompage"
            label="Extraire le coût estimé du pompage"
            defaultValue={formData.coutPompage}
            onChange={(e) => setShowCoutPompageCampaign(e.target.checked)}
          />
          {showCoutPompageCampaign && (
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="coutPompageCampaign"
                    choices={campaignChoicesCustom}
                    className="autocomplete-input"
                    optionText="name"
                    label="Campagne pour coût pompage"
                    defaultValue={formData.coutPompageCampaign}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          )}
          <BooleanInput
            source="tempsPeche"
            label="Extraire le temps de pêche, nombre de pêcheurs, coût estimé renseigné"
            defaultValue={formData.tempsPeche}
            onChange={(e) => setShowTempsPecheCampaign(e.target.checked)}
          />
          {showTempsPecheCampaign && (
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="tempsPecheCampaign"
                    choices={campaignChoicesCustom}
                    className="autocomplete-input"
                    optionText="name"
                    label="Campagne pour temps pêche"
                    defaultValue={formData.tempsPecheCampaign}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          )}
          <BooleanInput
            source="travauxEntretien"
            label="Extraire l'intitulé des travaux d'entretiens et coût estimé"
            defaultValue={formData.travauxEntretien}
            onChange={(e) =>
              setShowTravauxEntretienCampaign(e.target.checked)
            }
          />
          {showTravauxEntretienCampaign && (
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="travauxEntretienCampaign"
                    choices={campaignChoicesCustom}
                    className="autocomplete-input"
                    optionText="name"
                    label="Campagne pour travaux entretien"
                    defaultValue={formData.travauxEntretienCampaign}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          )}
          <BooleanInput
            source="extraireDonneesRegulations"
            label="Extraire données de Régulations"
            defaultValue={formData.extraireDonneesRegulations}
            onChange={(e) =>
              setShowExtraireDonneesRegulationsCampaign(e.target.checked)
            }
          />
          {showExtraireDonneesRegulationsCampaign && (
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="extraireDonneesRegulationsCampaign"
                    choices={campaignChoicesCustom}
                    className="autocomplete-input"
                    optionText="name"
                    label="Campagne pour données régulations"
                    defaultValue={formData.extraireDonneesRegulationsCampaign}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          )}
          <BooleanInput
            source="coutFertilization"
            label="Extraire le coût estimé de la fertilisation"
            defaultValue={formData.coutFertilization}
            onChange={(e) => setShowCoutFertilizationCampaign(e.target.checked)}
          />
          {showCoutFertilizationCampaign && (
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="coutFertilizationCampaign"
                    choices={campaignChoicesCustom}
                    className="autocomplete-input"
                    optionText="name"
                    label="Campagne pour coût fertilisation"
                    defaultValue={formData.coutFertilizationCampaign}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          )}
          <BooleanInput
            source="prixChaulage"
            label="Extraire le prix d'achat global de chaulage (quantité * prix d'achat)"
            defaultValue={formData.prixChaulage}
            onChange={(e) => setShowPrixChaulageCampaign(e.target.checked)}
          />
          {showPrixChaulageCampaign && (
            <div className="col-md-6 mb-3">
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <AutocompleteArrayInput
                    source="prixChaulageCampaign"
                    choices={campaignChoicesCustom}
                    className="autocomplete-input"
                    optionText="name"
                    label="Campagne pour prix chaulage"
                    defaultValue={formData.prixChaulageCampaign}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </div>
          )}
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          style={{ backgroundColor: "#4e73df" }}
        >
          Confirmer
        </button>
      </Form>
    </div>
  );
};

export default MultiRequester;
