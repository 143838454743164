import React, { useState } from "react";
import { Edit,useGetOne, useDataProvider,useListController,FormDataConsumer, useRedirect, useEditController, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import { useFormState } from 'react-final-form';
import moment from 'moment'
import 'moment/locale/fr'
import { RichTextInput } from 'ra-input-rich-text';


const FishEdit = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 5 },
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };


    });
  }
  const { record, isLoading } = useEditController();
  let [fishingStateSuccess, setFishingStateSuccess] = useState(null);
  const onSuccess = async () => {
    if (record) {      
      console.log(fishingStateSuccess);
      if (fishingStateSuccess === "/fishing_states/2" && record.waterBodyFishingState !== null) { // Si Vendu et WaterBodyFishingState existe
        await dataProvider.update('action_fishes', { id: record.id, data: { waterBodyFishingState: null } });
      } else if (fishingStateSuccess === "/fishing_states/1" && record.collector !== null) { // Si Gardé et Collector existe
        await dataProvider.update('action_fishes', { id: record.id, data: { collector: null } });
      }
    }
    
    
    redirect({
      pathname: '/action_fishes',
      search: `filter=${JSON.stringify({ waterBody: record.waterBody })}`,
    });
  };

  function GetNameSpieces(id) {
    const  spieciesName = useGetOne('specie_fishes', { id: id });
    if(spieciesName.data)  {
      return spieciesName.data.name;
    } else {
      return spieciesName.data;
    }
  }
  const formatSpecieFish = (record) => {
    if (!record.stage) {
      record.stage = "Stade non connu";
    }
    return record.stage;
  };
  let current = useListController();

    const  waterBody   = useGetOne('water_bodies', { id: record !== undefined ? record.waterBody : ''});
  return(
      <Edit mutationOptions={{ onSuccess }}  mutationMode="pessimistic">
        <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
        <SimpleForm sanitizeEmptyValues>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput source="specieFish"  reference="specie_fishes/uniq">
                <SelectInput optionText="name" label="Espèce" validate={[required()]} fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.specieFish &&
                <Grid container>
                  <Grid item xs={6}>
                    <ReferenceInput source="specieFish" reference={"specie_fishes?name="+GetNameSpieces(formData.specieFish)}>
                      <SelectInput  optionText={formatSpecieFish} label="Stade espèce"  fullWidth />
                    </ReferenceInput>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="numberHead" label="Nombre de tête(s)"  fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="averageWeight" label="Poids moyen (en kg)"  fullWidth />
            </Grid>
            <Grid item xs={3} className={"itemForm2"}>
              <NumberInput source="totalWeight" label="Poids total (en kg)"  validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput source="fishingState"  reference="fishing_states">
                <SelectInput optionText="name" label="Destination"  fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>

          <FormDataConsumer>
            {({ formData, ...rest }) => formData.fishingState === "/fishing_states/2" &&
                <Grid container>
                  <Grid item xs={6}>
                    <ReferenceInput source="collector"  reference="collectors">
                      <SelectInput optionText="name" label="Collecteur" fullWidth onChange={setFishingStateSuccess('/fishing_states/2')}/>
                    </ReferenceInput>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.fishingState === "/fishing_states/1" &&
                <Grid container>
                  <Grid item xs={6}>
                    <ReferenceInput source="waterBodyFishingState"  reference="water_bodies">
                      <SelectInput optionText="name" label="Liste étangs/bassins"  fullWidth onChange={setFishingStateSuccess('/fishing_states/1')}/>
                    </ReferenceInput>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <DateInput  source="date" label="Date" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.specieFish && formData.campaign && formData.fishingState !== "/fishing_states/4" && formData.fishingState !== "/fishing_states/3" &&
              <Grid container>
                <Grid item xs={6}>
                  <ReferenceInput source="fishPrice" reference={"fish_prices?campaign="+formData.campaign+"&specieFish="+formData.specieFish}>
                    <SelectInput optionText="disposalFeeCollector" label="Tarif (en € HT / kg)" fullWidth/>
                  </ReferenceInput>
                </Grid>
              </Grid>
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.specieFish && formData.campaign && formData.fishingState === "/fishing_states/4" && 
              <Grid container>
                <Grid item xs={6}>
                  <ReferenceInput source="fishPrice" reference={"fish_prices?campaign="+formData.campaign+"&specieFish="+formData.specieFish}>
                    <SelectInput optionText="costPrice" label="Tarif (en € HT / kg)" fullWidth/>
                  </ReferenceInput>
                </Grid>
              </Grid>
            }
          </FormDataConsumer>
          <Grid container>
          <Grid item xs={6}>
            <TextInput source="note" label="Remarque(s)" multiline fullWidth />
          </Grid>
        </Grid>


          <TextInput source="waterBody" className={"hidden"} type={"hidden"}/>
        </SimpleForm>
      </Edit>
  );
}


export default FishEdit;
