import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import {
  List, Datagrid, ExportButton, NumberInput, useDataProvider, usePermissions, Button, FilterForm,
  TextField, ReferenceInput, SelectInput, EditButton, useRecordContext, useRedirect,
  ReferenceField, FunctionField, CreateButton, useNotify, useListContext
} from 'react-admin';
import orderExporter from '../Tools/ExportExcel/exportWaterBodies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Stack, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import 'moment/locale/fr';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';

// Custom button component for managing records
const MyButton = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const location = useLocation();
  const waterBodyTypeFilterBtn = location.pathname === '/basins' ? '/basins' : '';

  const handleClick = () => {
    console.log(record);
    redirect(`/manager/${record.originId}${waterBodyTypeFilterBtn}`);
  };

  return (
    <button className={'ManagerButton'} onClick={handleClick}>
      Gérer
    </button>
  );
};

// Main list component for displaying water bodies
const WaterBodiesList = () => {
  useHideModifierLabel();

  const { permissions } = usePermissions();
  const location = useLocation();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const waterBodyTypeFilter = location.pathname === '/basins' ? 2 : 1;

  // Component to display when there are no records
  const Empty = () => {
    return (
      <Box textAlign="center" m={12}>
        <Typography variant="h4" paragraph>
          Pas encore d'étangs.
        </Typography>
        <Typography variant="body1">
          Voulez-vous en créer un ?
        </Typography>
        {waterBodyTypeFilter === 2 ?
          <Button href={"#/basins/create"} label="Créer" /> : <Button href={"#/water_bodies/create"} label="Créer" />}
      </Box>
    );
  }

  // Button component for creating new records
  const CustomCreateButton = (props) => {
    const redirect = useRedirect();

    return (
      <Button
        label="Créer"
        onClick={() => redirect('/basins/create')}
        startIcon={<AddIcon />}
        {...props}
      />
    );
  };

  // Button component for editing records
  const CustomEditButton = (props) => {
    const record = useRecordContext();
    const redirect = useRedirect();

    return (
      <Button
        label="Modifier"
        onClick={(e) => {
          e.stopPropagation();
          const encodedId = encodeURIComponent(`${record.id}`);
          redirect(`/basins/${encodedId}`);
        }}
        startIcon={<EditIcon />}
        {...props}
      />
    );
  };

  // Fetch choices for user dropdown
  const prepareUserChoices = async (dataProvider) => {
    const { data: users } = await dataProvider.getList('users', {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'lastName', order: 'ASC' },
    });

    return users.map(user => ({
      id: user.id,
      name: `${user.firstName} ${user.lastName} ${user.structureName ? '/ ' + user.structureName : ''}`
    }));
  };

  // Fetch choices for town dropdown
  const prepareTownChoices = async (dataProvider) => {
    const { data: communes } = await dataProvider.getList('towns', {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'name', order: 'ASC' },
    });

    return communes.map(commune => ({
      id: commune.id,
      name: `${commune.name}`
    }));
  };

  // Fetch choices for collector dropdown
  const prepareCollectorChoices = async (dataProvider) => {
    const { data: collectors } = await dataProvider.getList('collectors', {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'name', order: 'ASC' },
    });

    return collectors.map(collector => ({
      id: collector.id,
      name: `${collector.name}`
    }));
  };

  // Render date in custom format
  const dateRenderer = (record) => {
    const startDate = moment(record.startDate).format('YYYY');
    const endDate = moment(record.endDate).format('YYYY');
    return `${startDate} / ${endDate}`;
  };

  // Function to export filtered data
  const handleExportFiltered = async (filterValues) => {
    try {
      // Initialize variables for pagination
      const allData = [];
      let page = 1;
      const perPage = 100; // Fetch 100 records per request, adjust as needed
      let totalFetched = 0;
      let totalRecords = 0;

      do {
        // Fetch a page of data with filters applied
        const { data, total } = await dataProvider.getList('water_bodies', {
          pagination: { page, perPage },
          sort: { field: 'id', order: 'ASC' },
          filter: { ...filterValues, waterBodyType: waterBodyTypeFilter }
        });

        // Add fetched data to the allData array
        allData.push(...data);
        totalFetched += data.length;
        totalRecords = total; // Total records as returned by the API

        // Move to the next page
        page++;
      } while (totalFetched < totalRecords);

      console.log(allData);
      if (allData.length === 0) {
        notify('Aucune donnée à exporter', 'warning');
      } else {
        orderExporter(allData, null, dataProvider);
      }
    } catch (error) {
      console.error("Error fetching filtered data for export:", error);
      notify('Erreur lors de l\'exportation', 'error');
    }
  };

  // Toolbar for the list, including filters and export button
  const ListToolbar = () => {
    const [userChoices, setUserChoices] = useState([]);
    const [townChoices, setTownchoices] = useState([]);
    const [collectorChoices, setCollectorchoices] = useState([]);

    const { filterValues } = useListContext();

    useEffect(() => {
      if (permissions.includes('ROLE_ADMIN')) {
        prepareUserChoices(dataProvider).then(setUserChoices);
        prepareTownChoices(dataProvider).then(setTownchoices);
        prepareCollectorChoices(dataProvider).then(setCollectorchoices);
      }
    }, [dataProvider, permissions]);

    // Filter configuration
    const postFilters = [
      <SelectInput
        source="user"
        choices={userChoices}
        optionValue="id"
        optionText="name"
        emptyText="Tous les utilisateurs"
        label="Nom/Prénom/Organisme"
        fullWidth
        alwaysOn
      />,
      <SelectInput
        source="town"
        choices={townChoices}
        optionValue="id"
        optionText="name"
        emptyText="Toutes les communes"
        label="Communes"
        fullWidth
        alwaysOn
      />,
      <SelectInput
        source="currentCollector"
        choices={collectorChoices}
        optionValue="id"
        optionText="name"
        emptyText="Tous les collecteurs"
        label="Collecteurs"
        fullWidth
        alwaysOn
      />,
      <SelectInput
        source="electricity"
        label="Électricité"
        choices={[
          { id: 1, name: 'Oui' },
          { id: 0, name: 'Non' }
        ]}
        optionValue="id"
        optionText="name"
        alwaysOn
      />,
      <NumberInput
        source="waterSurface[gte]"
        label="Surface d'eau min (en ha)"
        fullWidth
        alwaysOn
      />,
      <NumberInput
        source="waterSurface[lte]"
        label="Surface d'eau max (en ha)"
        fullWidth
        alwaysOn
      />,
      <SelectInput
        source="aerator"
        label="Aération"
        choices={[
          { id: 1, name: 'Oui' },
          { id: 0, name: 'Non' }
        ]}
        optionValue="id"
        optionText="name"
        alwaysOn
      />,
      <SelectInput
        source="feeder"
        label="Nourrissage"
        choices={[
          { id: 1, name: 'Oui' },
          { id: 0, name: 'Non' }
        ]}
        optionValue="id"
        optionText="name"
        alwaysOn
      />,
      <SelectInput
        key="actionPumpings"
        source="actionPumpings.exists"
        label="Pompage"
        choices={[
          { id: 1, name: 'Oui' },
          { id: 0, name: 'Non' }
        ]}
        optionValue="id"
        optionText="name"
        alwaysOn
      />,
      <SelectInput
        key="actionProtections"
        source="actionProtections.exists"
        label="Protection cormorans"
        choices={[
          { id: 1, name: 'Oui' },
          { id: 0, name: 'Non' }
        ]}
        optionValue="id"
        optionText="name"
        alwaysOn
      />,
      <SelectInput
        key="actionLimngs"
        source="actionLimings.exists"
        label="Chaulage"
        choices={[
          { id: 1, name: 'Oui' },
          { id: 0, name: 'Non' }
        ]}
        optionValue="id"
        optionText="name"
        alwaysOn
      />,
      <SelectInput
        key="actionFertilizations"
        source="actionFertilizations.exists"
        label="Fertilisations"
        choices={[
          { id: 1, name: 'Oui' },
          { id: 0, name: 'Non' }
        ]}
        optionValue="id"
        optionText="name"
        alwaysOn
      />,
      <ReferenceInput source="campaign_exists" label="Campagne" reference="campaigns" alwaysOn>
        <SelectInput
          emptyText="Toutes les campagnes"
          optionText={dateRenderer}
          label="Campagne"
          fullWidth
        />
      </ReferenceInput>
    ];

    return (
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        {permissions !== undefined && permissions.includes('ROLE_ADMIN') &&
          <span style={{ display: 'contents' }}>
            <FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} />
            <FilterForm filters={postFilters} />
          </span>
        }
        <div style={{ display: 'flex' }}>
          {location.pathname === '/basins' ? <CustomCreateButton /> : <CreateButton />}
          <ExportButton label='Exporter' onClick={() => handleExportFiltered(filterValues)} />
        </div>
      </Stack>
    )
  }

  return (
    <div>
      <h1 className={"title"}>{waterBodyTypeFilter === 1 ? 'Mes étangs' : 'Mes bassins'}</h1>
      <List title="Mes Etangs" actions={<ListToolbar />} exporter={false} empty={<Empty />} filter={{ waterBodyType: waterBodyTypeFilter }} disableSyncWithLocation>
        <Datagrid>
          <TextField source="originId" label="#" />
          <TextField source="name" label="Nom" />
          <ReferenceField label="Commune" source="town" reference="towns" link={false}>
            <FunctionField render={record => record && `${record.name}`} />
          </ReferenceField>
          <TextField
            source="waterSurface"
            label={waterBodyTypeFilter === 1 ? "Surface en eau (en ha)" : "Surface en eau (en m²)"}
          />
          <TextField source="numDDPP" label="N° DDPP" />
          <MyButton className={'ManagerButton'}>Gérer</MyButton>
          <EditButton label="MODIFIER" />
        </Datagrid>
      </List>
    </div>
  )
};

export default WaterBodiesList;
