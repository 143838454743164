import * as React from 'react';
import { Edit, FormDataConsumer, useListController, useRedirect,useEditController, useGetOne, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { RichTextInput } from 'ra-input-rich-text';
import LevelIndicator from '.';

const LevelIndicatorEdit = () => {
  const redirect = useRedirect();
  const {data} = useGetList('campaigns', {
    sort: {field: 'name', order: 'ASC'},
    pagination: {page: 1, perPage: 5},
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate + " / " + endDate
          };


    });
  }
  const { record, isLoading } = useEditController();
  const onSuccess = () => {
    redirect({
      pathname: '/level_indicators',
    });
  };
  let current = useListController();
  return (
      <Edit mutationOptions={{ onSuccess }}  mutationMode="pessimistic">
        <SimpleForm sanitizeEmptyValues>
          <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''}</h1>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="technicalLevel" label="Niveau technique" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="brutYield" label="Rendement brut (en kg/ha)" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="fishStocking" label="Empoissonnage (en kg/ha)" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="netYield" label="Rendement net (en kg/ha)" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="growthCoefficient" label="Coefficient d'accroissement" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="profits" label="Recettes (en € HT/ha)" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="averageSellingPrice" label="Prix moyen de vente (en € HT/ha)" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="charges" label="Charges (en €/ha)" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="brutMargins" label="Marge brute (en € HT/ha)" fullWidth />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
  );
}
export default LevelIndicatorEdit;
