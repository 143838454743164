import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";

import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import { i18nProvider } from "./i18n";
import { authProvider } from "./providers/authProvider";
import { dataProvider } from "./providers/dataProviderHydra";
import { CustomRoutes, Resource} from "react-admin";

import { MyMenu } from './MyMenu';
import  WaterBodies  from './WaterBodies';
import  Aerator  from './Aerator';
import  Fertilization  from './Fertilization';
import  FishingTeam  from './FishingTeam';
import  Liming  from './Liming';
import  Manager  from './Manager';
import  Membership  from './Membership';
import  AdminManager  from './AdminManager';
import  Town  from './Town';
import  RegulatedAnimal  from './RegulatedAnimal';
import  LevelIndicator  from './LevelIndicator';
import  Pumping  from './Pumping';
import  Protection  from './Protection';
import  Assec  from './Assec';
import  Food  from './Food';
import  FishStocking  from './FishStocking';
import  Fish  from './Fish';
import  FishPrice  from './FishPrice';
import  News  from './News';
import  User  from './User';
import  BalanceSheet  from './BalanceSheet';
import  MaintenanceWork  from './MaintenanceWork';
import  CustomLayout from './MyLayout';
import  ContributionPrice  from './ContributionPrice';
import  BrandCompliance  from './BrandCompliance';
import  MyLoginPage from './MyLoginPage/LoginPage';
import  axiosInstance  from './Tools/Interceptors';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { MyAppBar } from './MyAppBar';
import ResetPasswordForm from "./MyLoginPage/ForgotPassword";
import ChangePasswordForm from "./MyLoginPage/ChangePassword";
import WaterBodiesRouter from "./WaterBodies/WaterBodiesRouter";
import GrantRequest from "./GrantRequest";
import Observation from "./Observation";
import MultiRequester from "./MultiRequester";
import MultiRequesterView from "./MultiRequester/view";



export default function App() {
    if (typeof window === "undefined") return <></>;
    if (dataProvider === "undefined") return <></>;
    return (

        <HydraAdmin
            menu={MyMenu}
            entrypoint={window.origin}
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            layout={CustomLayout}
            loginPage={MyLoginPage}
        >
            <Resource name="news" options={{ label: 'Actualité' }}  {...News} />

            <Resource name="water_bodies"  icon={<i className="fa-solid fa-water"></i>} options={{ label: 'étang' }} {...WaterBodies} />
            <Resource name="action_aerators" options={{ label: 'Aération' }} {...Aerator} />
            <Resource name="action_fertilizations" options={{ label: 'Fertilisation' }} {...Fertilization} />
            <Resource name="action_fishing_teams" options={{ label: 'Coût équipe de pêche' }} {...FishingTeam} />
            <Resource name="action_limings" options={{ label: 'Chaulage' }} {...Liming} />
            <Resource name="action_regulated_animals" options={{ label: 'Regulation ragondins cormorans' }} {...RegulatedAnimal} />
            <Resource name="action_pumpings" options={{ label: 'Pompage' }} {...Pumping} />
            <Resource name="action_protections" options={{ label: 'Protection cormorans' }} {...Protection} />
            <Resource name="action_assecs" options={{ label: 'Gestion de l\'assec' }} {...Assec} />
            <Resource name="action_foods" options={{ label: 'Alimentation' }} {...Food} />
            <Resource name="action_fish_stockings" options={{ label: 'Empoissonnage' }} {...FishStocking} />
            <Resource name="action_fishes" options={{ label: 'Pêche' }} {...Fish} />
            <Resource name="action_observations" options={{ label: 'Observations' }}  {...Observation} />

            <Resource name="fish_prices" options={{ label: 'Tarifs' }}  {...FishPrice} />
            <Resource name="member_ship_prices" options={{ label: 'Prix cotisation' }}  {...ContributionPrice} />
            <Resource name="users" options={{ label: 'Comptes' }}  {...User} />
            <Resource name="level_indicators" options={{ label: 'Indicateurs niveaux' }}  {...LevelIndicator} />
            <Resource name="brand_compliances" options={{ label: 'Conformité marque' }}  {...BrandCompliance} />
            <Resource name="grant_requests" options={{ label: 'Demande de subventions' }}  {...GrantRequest} />


            <Resource name="action_maintenance_works" options={{ label: 'Autres charges' }}  {...MaintenanceWork} />
            <CustomRoutes>
                <Route path="/basins/*" element={<WaterBodiesRouter />} />
            </CustomRoutes>

            <CustomRoutes>
                <Route path="/manager/:id/:type?" element={<Manager />} />
            </CustomRoutes>
            <CustomRoutes>
                <Route path="/Membership/" element={<Membership />} />
            </CustomRoutes>
            <CustomRoutes>
                <Route path="/admin_manager/" element={<AdminManager />} />
            </CustomRoutes>
            <CustomRoutes>
                <Route path="/multi_requester/view" element={<MultiRequesterView />} />
            </CustomRoutes>
            <CustomRoutes>
                <Route path="/balance_sheet" element={<BalanceSheet/>} />
            </CustomRoutes>
            <CustomRoutes>
                <Route path="/multi_requester" element={<MultiRequester/>} />
            </CustomRoutes>
            <CustomRoutes>
                <Route path="/forgot_password" element={<ResetPasswordForm/>} />
            </CustomRoutes>
            <CustomRoutes>
                <Route path="/change_password" element={<ChangePasswordForm/>} />
            </CustomRoutes>
            <ResourceGuesser name="aerators" />
            <ResourceGuesser name="assecs" />
            <ResourceGuesser name="campaigns" />
            <ResourceGuesser name="collectors" />
            <ResourceGuesser name="fertilizations" />
            <ResourceGuesser name="fishing_states" />
            <ResourceGuesser name="limings" />
            <ResourceGuesser name="operations" />
            <ResourceGuesser name="protections" />
            <ResourceGuesser name="regulated_animals" />
            <ResourceGuesser name="observations" />
            <ResourceGuesser name="specie_fishes" />
            <ResourceGuesser name="stocking_origins" />
            <ResourceGuesser name="towns" />
            <ResourceGuesser name="water_body_types" />
            {/*<ResourceGuesser name="users" />*/}


        </HydraAdmin>
    );
}
