import React, { useEffect, useState } from "react";
import "../index.css";
import { useDataProvider } from 'react-admin';
import { usePermissions } from 'react-admin';
import equals from "../img/equals.svg";
import TinyBarChart from '../Tools/Charts/TinyBarChart';
import CustomTable from '../Tools/Charts/Table';
import CoefficientCircle from '../Tools/Charts/CircularData';
import StackedBarChart from '../Tools/Charts/BarChartStacked';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import 'moment/locale/fr';
import { entrypoint } from "../config/index.js";

const WaterBodyStats = ({ filterCampaign1, waterBodyData, townData, collectorData, filterUser }) => {
    const [isTableVisible3, setTableVisible3] = useState(false);
    const [isTableVisible2, setTableVisible2] = useState(false);
    const [isTableVisible, setTableVisible] = useState(false);
    const [tabFishStockings, setTabFishStockings] = useState([]);
    const [tabFertilization, setTabFertilization] = useState([]);
    const [tabFood, setTabFood] = useState([]);
    const [tabLiming, setTabLiming] = useState([]);
    const [tabOthers, setTabOthers] = useState([]);
    const [growthCoeff, setGrowthCoeff] = useState([]);
    const [growthCoeffTotal, setGrowthCoeffTotal] = useState([]);
    const [multiplicationCoeff, setMultiplicationCoeff] = useState([]);
    const [fishStockingData, setFishStockingData] = useState([]);
    const [fishingData, setFishingData] = useState([]);
    const [summaryFishStocking, setSummaryFishStocking] = useState([]);
    const [summaryFish, setSummaryFish] = useState([]);
    const [averagePriceAll, setAveragePrice] = useState([]);
    const [fishingRevenue, setFishingRevenue] = useState([]);
    const [estimatedCosts, setEstimatedCosts] = useState([]);
    const [fsPerCategory, setFishStockingPerCategory] = useState([]);
    const [fishPerCategory, setFishPerCategory] = useState([]);
    const [fsPerCategoryKG, setFishStockingPerCategoryKG] = useState([]);
    const [fishPerCategoryKG, setFishPerCategoryKG] = useState([]);
    const [grossMargins, setGrossMargins] = useState([]);
    const [grossProfitsPerCategory, setGrossProfitsPerCategory] = useState([]);
    const [sumChargesPerClass, setSumChargesPerClass] = useState([]);
    const [multiplicationCoeffTotal, setMultiplicationCoeffTotal] = useState([]);
    const [observations, setObservations] = useState([]);

    const { permissions } = usePermissions();
    let waterBodyId = waterBodyData.id;
    let campaignId = '';
    if (filterCampaign1) {
        campaignId = filterCampaign1.split("/").pop();
    }
    if (waterBodyId) {
        waterBodyId = waterBodyId.split("/").pop();
    }

    const speciesColors = {
        Carpes: "#8249F4",
        Tanches: "#BBF449",
        Blancs: "#4966F4",
        Carnassiers: "#D749F4",
        Indésirables: "#F449BB",
        Autres: "#FFC107",

        Chaulage:"#8249F4",
        Alimentation:"#BBF449",
        Fertilisation:"#4966F4",
        Electricité:"#D749F4",
        Empoissonnage:"#F449BB",
        Pompage:"#B00020",
        Aérateur:"#FFC107"


    };



    const fetchDataGrowthCoefficient = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/growth_coefficient/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/growth_coefficient/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.growth_coeff;
            setGrowthCoeff(data);

        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchDataMultiplicationCoefficient = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/multiplication_coefficient/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/multiplication_coefficient/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.multiplication_coeff;

            setMultiplicationCoeff(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchDataMultiplicationCoefficientTotal = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/coeff_multi_total/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/coeff_multi_total/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.total_multiplication_coeff;

            setMultiplicationCoeffTotal(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataGrowthCoeffTotal = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/coeff_accroissement/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/coeff_accroissement/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.coefficient_accroissement;

            setGrowthCoeffTotal(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchDataTabFishStockings = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/tab_fishStocking_global/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/tab_fishStocking_global/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const stock = responseData.cout_total_stock;
            const achats = responseData.cout_total_achats;
            const total = responseData.cout_total;
            setTabFishStockings({ stock, achats, total });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataTabFertilization = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/tab_fertilization_global/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/tab_fertilization_global/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.fertilizers;
            const totaldata = responseData.fertilizers_totalcost;
            setTabFertilization({ data, totaldata });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataTabFood = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/tab_food_global/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/tab_food_global/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.food;
            const totaldata = responseData.food_totalcost;
            setTabFood({ data, totaldata });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataTabLiming = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/tab_liming_global/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/tab_liming_global/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.liming;
            const totaldata = responseData.liming_totalcost;
            setTabLiming({ data, totaldata });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataTabOthers = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/tab_other_global/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/tab_other_global/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const aeration = responseData.aeration;
            const pumpingCost = responseData.pumping_cost;
            const totaldata = responseData.total_cost;
            console.log(pumpingCost);
            setTabOthers({ aeration, pumpingCost, totaldata });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchFishStockingData = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/fishStocking_data/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/fishStocking_data/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const totalWeight = responseData.total_weight;
            const weigthPerSurface = responseData.weight_per_surface;
            const autoproductionPercentage = responseData.autoproduction_percentage
            setFishStockingData({totalWeight, weigthPerSurface, autoproductionPercentage});
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchFishingData = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/fishing_data/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/fishing_data/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const totalWeight = responseData.total_weight;
            const yieldGross = responseData.yield_gross;
            const yieldNet = responseData.yield_net
            setFishingData({totalWeight, yieldGross, yieldNet});
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchDataSummaryFishStocking = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/fishStocking_summaryByStage/fishstocking/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/fishStocking_summaryByStage/fishstocking/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const summary = responseData.summary;
            setSummaryFishStocking(summary);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchDataSummaryFish = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/fishStocking_summaryByStage/fish/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/fishStocking_summaryByStage/fish/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const summary = responseData.summary;
            setSummaryFish(summary);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };


    const fetchDataAveragePrice = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/average_price/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/average_price/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const averagePrice = responseData.average_price;
            const averagePriceFs = responseData.average_price_fs;
            const averagePriceFsOrigin2 = responseData.average_price_fs_origin2;
            setAveragePrice({averagePrice, averagePriceFs, averagePriceFsOrigin2});
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataFishingRevenue = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/fishing_revenue/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/fishing_revenue/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const fishingRevenue = responseData.fishing_revenue;
            setFishingRevenue(fishingRevenue);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchDataEstimatedCosts = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/estimated_costs/${campaignId}/${waterBodyId}/${filterUser}`
            : `${entrypoint}/dashboard/estimated_costs/${campaignId}/${waterBodyId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const maintenanceWork = responseData.maintenance_cost;
            const fishingTeam = responseData.fishing_team_cost;
            setEstimatedCosts({maintenanceWork, fishingTeam});
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchFishStockingPerCategory= async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/total_fish_stocking_ByWaterBody/${campaignId}/${waterBodyId}/null/${filterUser}`
            : `${entrypoint}/dashboard/total_fish_stocking_ByWaterBody/${campaignId}/${waterBodyId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const perCategory = responseData.fs_perCategory;
            const perCategoryKG = responseData.fs_perCategoryKG;
            setFishStockingPerCategoryKG(perCategoryKG);
            setFishStockingPerCategory(perCategory);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchFishingPerCategory = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ? `${entrypoint}/dashboard/total_fishing_ByWaterBody/${campaignId}/${waterBodyId}/null/${filterUser}`
            : `${entrypoint}/dashboard/total_fishing_ByWaterBody/${campaignId}/${waterBodyId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const perCategory = responseData.fish_perCategory;
            const perCategoryKG = responseData.fish_perCategoryKG;

            setFishPerCategory(perCategory);
            setFishPerCategoryKG(perCategoryKG);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataGrossMargins = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ?  `${entrypoint}/dashboard/gross_margins/${campaignId}/${waterBodyId}/null/${filterUser}`
            : `${entrypoint}/dashboard/gross_margins/${campaignId}/${waterBodyId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.data;
            const gmWithoutDivision = responseData.gmWithoutDivision;
            setGrossMargins({data, gmWithoutDivision});
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const fetchDataRecipePerCategory = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ?  `${entrypoint}/dashboard/gross_profits/${campaignId}/${waterBodyId}/null/${filterUser}`
            : `${entrypoint}/dashboard/gross_profits/${campaignId}/${waterBodyId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.data;
            const recipePerCategory = responseData.recipePerCategory;
            const recipeWithoutDivision = responseData.recipeWithoutDivision;
            setGrossProfitsPerCategory({recipePerCategory, data, recipeWithoutDivision});
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataSumChargePerClass = async (waterBodyId) => {
        try {
            const token = localStorage.getItem('token');
            let filterUserValid = filterUser != null && !isNaN(filterUser);
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
            ?  `${entrypoint}/dashboard/sum_charges/${campaignId}/${waterBodyId}/null/${filterUser}`
            : `${entrypoint}/dashboard/sum_charges/${campaignId}/${waterBodyId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.data;
            const chargesWithoutDivision = responseData.chargesWithoutDivision;
            const chargesPerClass = responseData.chargesPerClass;
            setSumChargesPerClass({chargesPerClass, data, chargesWithoutDivision});
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const dataProvider = useDataProvider();
    useEffect(() => {
        if (campaignId && waterBodyId) {
            
            const checkExistingCompliance = async () => {
                try {
                    const { data } = await dataProvider.getList('action_observations', {
                        sort: { field: 'id', order: 'ASC' },
                        pagination: { page: 1, perPage: 1 },
                        filter: {
                            waterBody: waterBodyId,
                            campaign: campaignId
                        }
                    });
                    if (data.length > 0) {
                        setObservations(data[0].generalObservation);
                    } else {
                        setObservations('');
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération de brandcompliance:', error);
                }
            };

            checkExistingCompliance();
        }
    }, [campaignId, waterBodyId, dataProvider]);

    useEffect(() => {

        fetchDataTabFishStockings(waterBodyId);
        fetchDataTabFertilization(waterBodyId);
        fetchDataTabFood(waterBodyId);
        fetchDataTabLiming(waterBodyId);
        fetchDataTabOthers(waterBodyId);
        fetchDataGrowthCoefficient(waterBodyId);
        fetchDataMultiplicationCoefficient(waterBodyId);
        fetchFishStockingData(waterBodyId);
        fetchDataSummaryFishStocking(waterBodyId);
        fetchDataSummaryFish(waterBodyId);
        fetchFishingData(waterBodyId);
        fetchDataAveragePrice(waterBodyId);
        fetchDataEstimatedCosts(waterBodyId);
        fetchDataFishingRevenue(waterBodyId);
        fetchFishStockingPerCategory(waterBodyId);
        fetchFishingPerCategory(waterBodyId);
        fetchDataGrossMargins(waterBodyId);
        fetchDataRecipePerCategory(waterBodyId);
        fetchDataSumChargePerClass(waterBodyId);
        fetchDataGrowthCoeffTotal(waterBodyId);
        fetchDataMultiplicationCoefficientTotal(waterBodyId);

    }, [waterBodyId, filterUser]);

    const waterSurface = waterBodyData ? waterBodyData.waterSurface : '';
    const formatNumber = (number) => {
        const parsedNumber = parseFloat(number);
        if (Number.isInteger(parsedNumber)) {
            return parsedNumber.toString();
        }

        return parsedNumber.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        });
    };


    const firstInfo = [
        { value: observations ? observations : '', label: observations ? 'Observations générales' : '' },
        { value: waterSurface ? waterSurface + ' ha' : '', label: waterSurface ? 'd\'eau' : '' },
        { value: townData ? townData.name : '', label: townData ? 'Commune' : '' },
        { value: collectorData ? collectorData.name : '', label: collectorData ? 'Collecteur' : '' },
    ];
    const sumFsPerCategory = Object.values(fsPerCategory).reduce((a, b) => a + b, 0);
    const sumFishPerCategory = Object.values(fishPerCategory).reduce((a, b) => a + b, 0);
    let sumProfitsPerCategory = 0;
    if (grossProfitsPerCategory && Array.isArray(grossProfitsPerCategory.recipePerCategory)) {
    sumProfitsPerCategory = grossProfitsPerCategory.recipePerCategory.reduce((total, category) => {
        if (category.recipe) {
        return total + category.recipe;
        }
        return total;
    }, 0);
    }
    let sumSumChargesPerClass = 0;
    if (sumChargesPerClass && Array.isArray(sumChargesPerClass.chargesPerClass)) {
        sumSumChargesPerClass = sumChargesPerClass.chargesPerClass.reduce((total, category) => {
            if (category.charges) {
            return total + category.charges;
            }
            return total;
        }, 0);
    }


    const roundToNextMagnitude = (value) => {
        const magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(value))));
        const roundedValue = Math.ceil(Math.abs(value) / magnitude) * magnitude;
        return value >= 0 ? roundedValue : -roundedValue;
    };
    const roundedGrossMargins = roundToNextMagnitude(grossMargins.data);
    const roundedFsPerCategory = roundToNextMagnitude(sumFsPerCategory);
    const roundedFishPerCategory = roundToNextMagnitude(sumFishPerCategory);
    const roundedProfitsPerCategory = roundToNextMagnitude(sumProfitsPerCategory);
    const roundedChargesPerClass = roundToNextMagnitude(sumSumChargesPerClass);
    const resultWaterMinusTNW = grossMargins.data - (estimatedCosts.maintenanceWork + estimatedCosts.fishingTeam);

    function customSort(a, b) {
        const valueA = a.valeur !== undefined ? a.valeur : a.value;
        const valueB = b.valeur !== undefined ? b.valeur : b.value;

        return valueB - valueA;
    }

    const [useKG, setUseKG] = useState(null); // Initial value
    const [selectedBtn, setSelectedBtn] = useState('kg/ha'); // 'kg' or 'kg/ha'
    const [useKGFish, setUseKGFish] = useState(null); // Initial value
    const [selectedBtnFish, setSelectedBtnFish] = useState('kg/ha'); // 'kg' or 'kg/ha'

    // Function to set useKG to true
    const handleKGFishStocking = () => {
        setUseKG(true);
        setSelectedBtn('kg'); // Set the selected button
      };

      const handleKGHaFishStocking = () => {
        setUseKG(false);
        setSelectedBtn('kg/ha'); // Set the selected button
      };
      const handleKGFish = () => {
        setUseKGFish(true);
        setSelectedBtnFish('kg');
      };

      const handleKGHaFish = () => {
        setUseKGFish(false);
        setSelectedBtnFish('kg/ha');
      };

    const dataKGFishStocking = Object.entries(useKG ? fsPerCategoryKG : fsPerCategory).map(([key, value]) => ({
        name: `${key} ${useKG ? '(kg)' : '(kg/ha)'}`,
        valeur: value,
        fill: speciesColors[key] || "#000000"
    })).sort(customSort);

    const dataKGFish = Object.entries(useKGFish ? fishPerCategoryKG : fishPerCategory).map(([key, value]) => ({
        name: `${key} ${useKGFish ? '(kg)' : '(kg/ha)'}`,
        valeur: value,
        fill: speciesColors[key] || "#000000"
    })).sort(customSort);


    return (

        <div className="backgroundBalance">
            <div className="background-container container-fluid">
                <div style={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'center', margin: '10px 0 10px 0' }}>
                    {firstInfo.map((item, index) => (
                        <div className="divInfoFish" key={index}>
                            <p className="pInfoWater">{item.value}</p>
                            <p className="pInfoWater2">{item.label}</p>
                        </div>
                    ))}
                </div>
            </div>

            <p className="section-title">Bilan technique</p>
            <div className="chart-container watercontainer">

                <div className="background-container container-fluid watersection">
                    <div>

                        <p className="pTitle">EMPOISSONNAGE</p>
                        <div className="divInfoGlobal">
                            <div className="background-container-info">
                                <p className="numberInfo">{fishStockingData.autoproductionPercentage} %</p>
                                <p className="textInfo">d'empoissonnage autoproduit</p>
                            </div>
                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(fishStockingData.totalWeight)} kg</p>
                                <p className="textInfo">empoissonné au total</p>
                            </div>
                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(fishStockingData.weigthPerSurface)} kg/ha</p>
                                <p className="textInfo">de mise en charge</p>
                            </div>
                        </div>
                        <div className="chart-container row">
                            <div style={{ marginBottom: '20px', minWidth: '100%' }}>

                            <StackedBarChart
                                title="Empoissonnage en"
                                size={300}
                                ticks={[0, useKG ? (roundedFsPerCategory*waterSurface) : roundedFsPerCategory]}
                                unityValue={selectedBtn}
                                data={dataKGFishStocking}
                                switchBtn={true}
                                titleBtn="kg"
                                functionBtn={handleKGFishStocking}
                                switchBtn2={true}
                                titleBtn2="kg/ha"
                                functionBtn2={handleKGHaFishStocking}
                                selectedBtn={selectedBtn}

                            />


                            </div>
                        </div>
                        <p className="invisibleInfo" onClick={() => setTableVisible(!isTableVisible)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCircleInfo} />
                            Visualiser détails empoissonnage</p>
                        {isTableVisible && (
                            <CustomTable
                                columns={[
                                    { key: 'stage', label: 'Stade espèce' },
                                    { key: 'number_head', label: 'Nombre de tête' },
                                    { key: 'total_weight', label: 'Poids total (kg)' },
                                    { key: 'number_headWB', label: 'Nombre de tête/ha' },
                                    { key: 'total_weightWB', label: 'Poids total/ha (en kg/ha)' },
                                ]}
                                data={summaryFishStocking.map(item => ({
                                    stage: item.stage,
                                    number_head: item.number_head === 0 ? 'NC' : item.number_head,
                                    total_weight: item.total_weight,
                                    number_headWB: item.number_headWB === 0 ? 'NC' : item.number_headWB,
                                    total_weightWB: item.total_weightWB
                                }))}
                            />
                        )}
                    </div>
                </div>
                <div className="background-container container-fluid watersection">
                    <div>
                        <p className="pTitle">PÊCHE</p>
                        <div className="divInfoGlobal">
                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(fishingData.totalWeight)} kg</p>
                                <p className="textInfo">pêchés</p>
                            </div>
                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(fishingData.yieldGross)} kg/ha</p>
                                <p className="textInfo">de rendement brut</p>
                            </div>
                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(fishingData.yieldNet)} kg/ha</p>
                                <p className="textInfo">de rendement net</p>
                            </div>


                        </div>
                        <div className="chart-container row">
                            <div style={{ marginBottom: '20px', minWidth: '100%' }}>
                                <StackedBarChart
                                    title="Pêche en"
                                    size={300}
                                    unityValue={selectedBtn}
                                    ticks={[0, useKGFish ? (roundedFishPerCategory*waterSurface) : roundedFishPerCategory]}
                                    data={dataKGFish}
                                    switchBtn={true}
                                    titleBtn="kg"
                                    functionBtn={handleKGFish}
                                    switchBtn2={true}
                                    titleBtn2="kg/ha"
                                    functionBtn2={handleKGHaFish}
                                    selectedBtn={selectedBtnFish}
                                />
                            </div>
                        </div>
                        <p className="invisibleInfo" onClick={() => setTableVisible2(!isTableVisible2)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCircleInfo} />
                            Visualiser détails pêche</p>
                        {isTableVisible2 && (
                            <CustomTable
                                columns={[
                                    { key: 'stage', label: 'Stade espèce' },
                                    { key: 'number_head', label: 'Nombre de tête' },
                                    { key: 'total_weight', label: 'Poids total (kg)' },
                                    { key: 'number_headWB', label: 'Nombre de tête/ha' },
                                    { key: 'total_weightWB', label: 'Poids total/ha (en kg/ha)' },
                                ]}
                                data={summaryFish.map(item => ({
                                    stage: item.stage,
                                    number_head: item.number_head === 0 ? 'NC' : item.number_head,
                                    total_weight: item.total_weight,
                                    number_headWB: item.number_headWB === 0 ? 'NC' : item.number_headWB,
                                    total_weightWB: item.total_weightWB
                                }))}
                            />
                        )}
                    </div>
                </div>
                <div className="background-container container-fluid watersection">
                    <div>
                        <p className="pTitle">COEFFICIENT D'ACCROISSEMENT MOYEN</p>
                        <div className="divInfoGlobal">
                            <div className="background-container-info" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                <CoefficientCircle total_coeff={growthCoeffTotal} />
                            </div>
                        </div>
                        <div className="chart-container row">
                            <div style={{ marginBottom: '20px', minWidth: '100%' }}>
                                {growthCoeff && <TinyBarChart
                                    title="Coefficient d'accroissement/espèce"
                                    ticks={[0, 2.00, 4.00, 4.00, 6.00]}
                                    data={Object.keys(growthCoeff).map((species, index) => ({
                                        name: species,
                                        value: growthCoeff[species] ?? 0,
                                        fill: speciesColors[species]
                                    })).sort(customSort)}
                                />}

                            </div>
                        </div>
                        <div className="chart-container row">
                            <div style={{ marginBottom: '20px', minWidth: '100%' }}>
                                {multiplicationCoeff && <TinyBarChart
                                    title="Coefficient multiplicateur/espèce"
                                    ticks={[0, 2.00, 4.00, 4.00]}
                                    data={multiplicationCoeff && Object.keys(multiplicationCoeff).map((species, index) => ({
                                        name: species,
                                        value: multiplicationCoeff[species] ?? 0,
                                        fill: speciesColors[species]
                                      })).sort(customSort)}

                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="section-title">Indicateurs économiques</p>
            <div className="background-container container-fluid">

                <div className="chart-container-center flextab2">

                    <div className="divInfoCenter" style={{display:'flex'}}>
                        <div style={{marginRight:80}}>
                            <p className="numberInfo" style={{ color: (grossMargins.gmWithoutDivision >= 0) ? '#4CAF50' : '#B00020' }}>{formatNumber(grossMargins.gmWithoutDivision)} € HT</p>
                            <p className="textInfo">marge brute étang</p>
                        </div>
                        <div>
                            <p className="numberInfo" style={{ color: (grossMargins.data >= 0) ? '#4CAF50' : '#B00020' }}>{formatNumber(grossMargins.data)} € HT/ha</p>
                            <p className="textInfo">marge brute/ha</p>
                        </div>
                    </div>
                </div>
                <p className="pTitleLeft">DETAILS DU CALCUL DE LA MARGE BRUTE DE L'ETANG</p>
                <div className="chart-container watercontainer">

                    <div className="watersection">
                        <div style={{ marginBottom: 77 }}>
                        {grossProfitsPerCategory.recipePerCategory ?
                            <StackedBarChart
                                title={[
                                    `Recettes (en € HT)`,
                                    <span style={{ color: '#4CAF50' }}>{`${formatNumber(grossProfitsPerCategory.recipeWithoutDivision)} €`}</span>
                                ]}
                                unityValue='€ HT'
                                legend="none"
                                ticks={[0, roundedProfitsPerCategory]}
                                data={grossProfitsPerCategory.recipePerCategory.map(({ name, recipe }) => ({
                                    name: `${name} (en € HT)`,
                                    valeur: recipe,
                                    fill: speciesColors[name]
                                })).sort(customSort)}
                            /> : ''
                        }
                        </div>
                        <div className="divInfoGlobal_2">
                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(averagePriceAll.averagePrice)} €/kg HT</p>
                                <p className="textInfo">prix moyen de vente</p>
                            </div>
                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(multiplicationCoeffTotal)}</p>
                                <p className="textInfo">coefficient multiplicateur</p>
                            </div>
                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(fishingRevenue)} € HT</p>
                                <p className="textInfo">chiffre d'affaires de la pêche</p>
                            </div>
                        </div>
                    </div>
                    <div className="imgresp chart-icon2">
                        <FontAwesomeIcon icon={faCircleMinus} size="xl" />
                    </div>
                    <div className="watersection">
                        <div style={{ marginBottom: 50 }}>
                            {sumChargesPerClass.chargesPerClass ?
                                <StackedBarChart
                                    title={[
                                        `Charges (en € HT)`,
                                        <span style={{ color: '#B00020' }}>{`${formatNumber(sumChargesPerClass.chargesWithoutDivision)} €`}</span>
                                    ]}
                                    unityValue='€ HT'
                                    legend="none"
                                    ticks={[0, roundedChargesPerClass]}
                                    data={sumChargesPerClass.chargesPerClass.map(({ name, charges }) => ({
                                        name: `${name} (en € HT)`,
                                        valeur: charges,
                                        fill: speciesColors[name]
                                    })).sort(customSort)}
                                /> : ''
                            }

                            <p className="invisibleInfo" onClick={() => setTableVisible3(!isTableVisible3)}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCircleInfo} />
                                Visualiser le détail des charges de l'étang</p>
                        </div>
                        <div className="divInfoGlobal_2">
                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(averagePriceAll.averagePriceFs)} €/kg HT</p>
                                <p className="textInfo">prix moyen de l'empoissonnage</p>
                            </div>
                        </div>
                    </div>
                    {/* <FontAwesomeIcon icon={faCircleMinus} size="xl" className="chart-icon" /> */}
                    <div className="imgresp chart-icon2">
                        <img src={equals} style={{ width: 40, marginRight: -7, marginLeft: -7, pointerEvents: 'none' }} className="equals" />
                    </div>
                    <div className="watersection">
                        <div style={{ marginBottom: 77 }}>
                            {grossMargins.data !== null && grossMargins.data !== undefined ?
                            <StackedBarChart
                                title={[
                                    `Marge brute (en € HT)`,
                                    <span style={{ color: (`${grossMargins.gmWithoutDivision}` >= 0) ? '#4CAF50' : '#B00020' }}>{`${formatNumber(grossMargins.gmWithoutDivision)} €`}</span>
                                ]}
                                unityValue='€ HT'
                                legend="none"
                                ticks={[0, roundedGrossMargins]}
                                data={[{ name: 'Marge brute (en € HT)', valeur: grossMargins.gmWithoutDivision, fill: grossMargins.gmWithoutDivision >= 0 ? '#4CAF50' : '#B00020' }]}
                            /> : ''}
                        </div>
                        <div className="divInfoGlobal_2">

                            <div className="background-container-info">
                                <p className="numberInfo">{formatNumber(averagePriceAll.averagePriceFsOrigin2)} € HT</p>
                                <p className="textInfo">valeur du stock</p>
                            </div>
                        </div>
                    </div>
                </div>

                {isTableVisible3 && (
                    <>
                        <p className="pTitleLeft"><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCircleInfo} />  Détail des charges de l'étang</p>
                        <div className="chart-container container-fluid">
                            <div className="divInfoGlobal_2 flextab">
                                <CustomTable
                                    columns={[
                                        { key: 'fishstocking', label: 'Empoisonnage' },
                                        { key: 'totalcost', label: 'Coût (en € HT)' },
                                    ]}
                                    data={[
                                        { id: 1, fishstocking: 'Stock', totalcost: `${tabFishStockings.stock} €` },
                                        { id: 2, fishstocking: 'Achats de poissons', totalcost: `${tabFishStockings.achats} €` },
                                        { id: 3, fishstocking: 'Total', totalcost: `${tabFishStockings.total} €` },
                                    ]}
                                />
                                <CustomTable
                                    columns={[
                                        { key: 'fertilization', label: 'Fertilisation' },
                                        { key: 'quantity', label: 'Quantité' },
                                        { key: 'totalcost', label: 'Coût (en € HT)' },
                                    ]}
                                    data={tabFertilization.data && tabFertilization.data.length > 0 ? (
                                        tabFertilization.data.map((item, index) => ({
                                            id: index + 1,
                                            fertilization: item.name,
                                            quantity: item.quantity,
                                            totalcost: `${item.cost} €`,
                                        })).concat({
                                            id: tabFertilization.data.length + 1,
                                            fertilization: 'Total',
                                            quantity: '',
                                            totalcost: `${tabFertilization.totaldata} €`,
                                        })
                                    ) : [
                                        {
                                            id: 1,
                                            fertilization: 'Total',
                                            quantity: '',
                                            totalcost: `${tabFertilization.totaldata} €`,
                                        }
                                    ]}
                                />

                                <CustomTable
                                    columns={[
                                        { key: 'otherinputs', label: 'Autres intrants' },
                                        { key: 'totalcost', label: 'Coût (en € HT)' },
                                    ]}
                                    data={tabOthers.aeration && tabOthers.aeration.length > 0 ? (
                                        tabOthers.aeration.map((item, index) => ({
                                            id: index + 1,
                                            otherinputs: item.name,
                                            totalcost: `${item.cost} €`,
                                        })).concat({
                                            id: tabOthers.aeration.length + 1,
                                            otherinputs: 'Pompage',
                                            totalcost: `${tabOthers.pumpingCost} €`,
                                        }).concat({
                                            id: tabOthers.aeration.length + 2,
                                            otherinputs: 'Total',
                                            totalcost: `${tabOthers.totaldata} €`,
                                        })
                                    ) : [
                                        {
                                            id: 1,
                                            otherinputs: 'Pompage',
                                            totalcost: `${tabOthers.pumpingCost} €`,
                                        },
                                        {
                                            id: 2,
                                            otherinputs: 'Total',
                                            totalcost: `${tabOthers.totaldata} €`,
                                        }
                                    ]}

                                />
                                <CustomTable
                                    columns={[
                                        { key: 'food', label: 'Aliments' },
                                        { key: 'quantity', label: 'Quantité (en kg)' },
                                        { key: 'totalcost', label: 'Coût (en € HT)' },
                                    ]}
                                    data={tabFood.data && tabFood.data.length > 0 ? (
                                        tabFood.data.map((item, index) => ({
                                            id: index + 1,
                                            food: item.name,
                                            quantity: item.quantity,
                                            totalcost: `${item.cost} €`,
                                        })).concat({
                                            id: tabFood.data.length + 1,
                                            food: 'Total',
                                            quantity: '',
                                            totalcost: `${tabFood.totaldata} €`,
                                        })
                                    ) : [
                                        {
                                            id: 1,
                                            food: 'Total',
                                            quantity: '',
                                            totalcost: `${tabFood.totaldata} €`,
                                        }
                                    ]}
                                />

                                <CustomTable
                                    columns={[
                                        { key: 'liming', label: 'Chaulage' },
                                        { key: 'quantity', label: 'Quantité (en T)' },
                                        { key: 'totalcost', label: 'Coût (en € HT)' },
                                    ]}
                                    data={tabLiming.data && tabLiming.data.length > 0 ? (
                                        tabLiming.data.map((item, index) => ({
                                            id: index + 1,
                                            liming: item.name,
                                            quantity: item.quantity,
                                            totalcost: `${item.cost} €`,
                                        })).concat({
                                            id: tabLiming.data.length + 1,
                                            liming: 'Total',
                                            quantity: '',
                                            totalcost: `${tabLiming.totaldata} €`,
                                        })
                                    ) : [
                                        {
                                            id: 1,
                                            liming: 'Total',
                                            quantity: '',
                                            totalcost: `${tabLiming.totaldata} €`,
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="divInfoGlobal">
                    <p className="pTitleLeft">CALCUL DU RÉSULTAT DE L'ÉTANG</p>
                    <div className="tablew50">
                        <CustomTable
                            columns={[
                                { key: 'label', label: '' },
                                { key: 'value', label: '' },
                            ]}
                            data={[
                                { id: 1, label: 'Marge brute étang', value: `${formatNumber(Math.round(grossMargins.gmWithoutDivision))} € HT` },
                                { id: 2, label: 'Coût équipe pêche', value: `${formatNumber(estimatedCosts.fishingTeam)} € HT`},
                                { id: 3, label: 'Travaux d\'entretien', value: `${formatNumber(estimatedCosts.maintenanceWork)} € HT` },
                                { id: 4, label: 'Résultat', value: `${formatNumber(grossMargins.gmWithoutDivision-(estimatedCosts.fishingTeam+estimatedCosts.maintenanceWork))} € HT` },
                            ]}

                        ></CustomTable>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default WaterBodyStats;
