// in src/posts.js
import { List,ReferenceField,useRecordContext,useDataProvider, ReferenceInput, SelectInput, FilterForm, useListContext,useList,FunctionField,useGetOne,useListController,TopToolbar,ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box,  Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import {orderExporter, orderExporterPDF} from '../Tools/ExportExcel/exportFish';
import React, { useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';


const Empty = () => {
    let data = useListController();
    return(
        <Box textAlign="center" m={12}>
            <Typography variant="h4" paragraph>
                Pas encore de Chaulage.
            </Typography>
            <Typography variant="body1">
                Voulez-vous en créer un ?
            </Typography>
            <Button href={"#/action_fishes/create?id="+data.filterValues.waterBody} label="Créer"/>
        </Box>
    );
}
const dateRenderer = (record) => {
  const startDate = moment(record.startDate).format('YYYY');
  const endDate = moment(record.endDate).format('YYYY');
  return `${startDate} / ${endDate}`;
};
const PostListActions = (waterName) => {
    let data = useListController();
    const dataProvider = useDataProvider();
    const [campaignValue, setCampaignValue] = useState(null); // valeur par défaut

    const handleSelectChange = (event) => {
      setCampaignValue(event.target.value);
      
    };
    let waterNameExport = waterName?.waterName;
    if (waterNameExport) {
    waterNameExport = waterNameExport.replace(/[*?:\/[\]]/g, '_'); 
    }
  let exportFileName = 'Pêche - ' + (waterNameExport || '');
    const postFilters = [
      <ReferenceInput source="campaign" label="Campagne" reference="campaigns" alwaysOn>
          <SelectInput 
              emptyText="Toutes les campagnes" 
              optionText={dateRenderer} 
              label="Campagne" 
              fullWidth  
              defaultValue="TOUS" 
              onChange={handleSelectChange}
          />
      </ReferenceInput>
    ];
    return (
        <TopToolbar>
          <Stack direction="row" justifyContent="flex-end"   alignItems="center">

          <span style={{display: 'contents'}} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFilters} /></span>
          <div style={{display: 'flex'}}>
            <Button href={"#/action_fishes/create?id="+data.filterValues.waterBody} label="Créer"/>
            <div style={{marginLeft:10}}>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#38a9b4', height: 30, width: 'auto', border: 'none', margin: '0 20px 0 0', display:'flex', alignItems:'center' }}>
                <span style={{ marginRight: 10 }}>Exporter</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu" style={{ padding: 10, border: '1px solid #f2f2f2', width:200 }}>
                  <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <ExportButton label='Exporter (Excel)' onClick={() => orderExporter(data.data, null, dataProvider, campaignValue, exportFileName)}/>
                    <ExportButton label='Exporter (PDF)' onClick={() => orderExporterPDF(data.data, null, dataProvider, campaignValue, exportFileName)}/>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              </div>
            </div>
          </Stack>
        </TopToolbar>
    );
}
const TotalTextField = ({ source }) => {
  const record = useRecordContext();
  const price = useGetOne('FishPrice', { id: record.fishPrice });
  if (price.data !== undefined) {
    if (record[source] === undefined) {
      return (<span style={{color:'red'}}>Non saisi</span>);
    } else {
      if (record.fishingState === "/fishing_states/4") {
        return (<span style={{color:'black'}}>{record.totalWeight*price.data.costPrice}</span>);

      }
        else {
          return (<span style={{color:'black'}}>{record.totalWeight*price.data.disposalFeeCollector}</span>);
      }
    }
  }

};


const CostTextField = ({ source }) => {
  const record = useRecordContext();
  const price = useGetOne('FishPrice', { id: record.fishPrice });
  if (price.data !== undefined) {
    if (record.fishingState === "/fishing_states/4") {
      return (<span style={{color:'black'}}>{price.data.costPrice}</span>);
    } else {
      return (<span style={{color:'black'}}>{price.data.disposalFeeCollector}</span>);
    }
  }

};


const FishList = () => {
    useHideModifierLabel();
  let data = useListController();
  const waterBody = useGetOne('water_bodies', { id: data?.filterValues?.waterBody });
    return (
    <List actions={<PostListActions waterName={waterBody?.data?.name}/>} exporter={false} empty={<Empty />}>
        <h1 className={"titleName"}>{data !== undefined ? data.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
        <Datagrid>
      <TextField source="originId" label="#" />
          <ReferenceField link={false} label="Campagne" source="campaign" reference="campaigns">
            <FunctionField render={record => record && `${moment(record.startDate).format('YYYY')} / ${moment(record.endDate).format('YYYY')}`} />
          </ReferenceField>
          <DateField  source="date" label="Date" showTime={true} options={{ year: 'numeric', month: 'numeric', day: 'numeric'}} />
          <ReferenceField label="Destination précise" source="fishingState" reference="fishingState">
            <FunctionField render={record => record && `${record.name}`} />
          </ReferenceField>
          <ReferenceField label="Acheteur" source="collector" reference="collectors" link={false}>
            <FunctionField render={record => record && record.name !== "" ?`${record.name}` : ""} emptyText="missing data"/>
          </ReferenceField>
          <ReferenceField label="Etang/bassin de destination" source="waterBodyFishingState" reference="water_bodies" link={false}>
            <FunctionField render={record => record && record.name !== "" ?`${record.name}` : ""} emptyText="missing data"/>
          </ReferenceField>
          <ReferenceField label="Espèce" source="specieFish" reference="specieFish">
            <FunctionField render={record => record && `${record.name}`} />
          </ReferenceField>
          <ReferenceField label="Stade espèce" source="specieFish" reference="specieFish">
            <FunctionField render={record => record && record.stage !== "" ?`${record.stage}` : "Stade non connu"} emptyText="missing data"/>
          </ReferenceField>
          <TextField source="numberHead" label="Nombre de têtes"/>
          <TextField source="averageWeight" label="Poids moyen (en kg)"/>
          <TextField source="totalWeight" label="Poids total (en kg)"/>
          <CostTextField source="fishPrice" label="Coût unitaire (€ HT /kg)"/>
          <TotalTextField source="fishPrice" label="Coût total (en € HT)"/>



          <EditButton label="MODIFIER"/>
    </Datagrid>
  </List>
);
}
export default FishList;
