import React, { useState, useEffect } from "react";
import "../index.css";
import { usePermissions } from 'react-admin';
import CustomTable from '../Tools/Charts/Table';
import 'moment/locale/fr';
import { entrypoint } from "../config/index.js";

const Checked = ({waterBodyList, filterUser, filterCampaign1}) => {
  let [waterBodies, setWaterBodies] = useState([]);
  let [yieldWB, setYieldWB] = useState([]);
  let [fishStocking, setFishStocking] = useState([]);
  let [fishingData, setFishingData] = useState([]);
  let [growthCoeff, setGrowthCoeff] = useState([]);
  let [grossProfits, setGrossProfits] = useState([]);
  let [averagePrice, setAveragePrice] = useState([]);
  let [sumCharges, setSumCharges] = useState([]);
  let [grossMargins, setGrossMargins] = useState([]);
  let [levelIndicator, setLevelIndicator] = useState([]);

  //CACHE REQUETE
  const [waterBodyIdsCache, setWaterBodyIdsCache] = useState({});
  const [fishStockingCache, setFishStockingCache] = useState({});
  const [dataYieldCache, setDataYieldCache] = useState({});
  const [fishingDataCache, setFishingDataCache] = useState({});
  const [growthCoeffCache, setGrowthCoeffCache] = useState({});
  const [recipeCache, setRecipeCache] = useState({});
  const [averagePriceCache, setAveragePriceCache] = useState({});
  const [sumChargesCache, setSumChargesCache] = useState({});
  const [grossMarginsCache, setGrossMarginsCache] = useState({});
  const [dataCache, setDataCache] = useState({});

  const { permissions } = usePermissions();
  let campaignId = '';
    if (filterCampaign1) {
        campaignId = filterCampaign1.split("/").pop();
    }
    let waterBodyIdList = [];

  

  useEffect(() => {
    if (waterBodyList && Array.isArray(waterBodyList)) {
      console.log(waterBodyList)
      waterBodyIdList = waterBodyList.map(url => url.split("/").pop());
    }
  }, [waterBodyList]);
  const fetchWaterBodyIds = async () => {
    try {
      let idParams = "";
      if (waterBodyIdList.length > 0) {
        idParams = waterBodyIdList.map(id => `id[]=${id}`).join('&');
      }
  
      const cacheKey = `${filterUser}-${idParams}`;
      if (waterBodyIdsCache[cacheKey] !== undefined) {
        setWaterBodies(waterBodyIdsCache[cacheKey]);
        return waterBodyIdsCache[cacheKey];
      }
  
      const token = localStorage.getItem('token');
      let url = (waterBodyIdList.length > 0) 
        ? `${entrypoint}/water_bodies?user=${filterUser}&${idParams}` 
        : `${entrypoint}/water_bodies?user=${filterUser}`;
        
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      
      const responseData = await response.json();
      const waterBodies = responseData['hydra:member'];
      const waterBodyDetails = waterBodies.map((waterBody) => {
        return { id: waterBody.id, waterSurface: waterBody.waterSurface, name: waterBody.name };
      });
      
      setWaterBodies(waterBodyDetails);
  
      // Mise en cache des données
      setWaterBodyIdsCache(prevCache => ({
        ...prevCache,
        [cacheKey]: waterBodyDetails
      }));
  
      return waterBodyDetails;
  
    } catch (error) {
      console.error("Erreur lors de la récupération des IDs des étangs :", error);
      return [];
    }
  };
  
  const fetchFishStockingPerCategory = async (waterBodyId) => {
    try {
      const cacheKey = `${campaignId}-${waterBodyId}-${filterUser}`;
      if (fishStockingCache[cacheKey] !== undefined) {
        return fishStockingCache[cacheKey];
      }
  
      const token = localStorage.getItem('token');
      let filterUserValid = filterUser != null && !isNaN(filterUser);
      let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
        ? `${entrypoint}/dashboard/total_fish_stocking_ByWaterBody/${campaignId}/${waterBodyId}/null/${filterUser}`
        : `${entrypoint}/dashboard/total_fish_stocking_ByWaterBody/${campaignId}/${waterBodyId}/null/null`;
        
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
  
      const responseData = await response.json();
      const fishStocking = responseData.fishstockingwb;
  
      // Mise en cache des données
      setFishStockingCache(prevCache => ({
        ...prevCache,
        [cacheKey]: fishStocking
      }));
  
      return fishStocking;
  
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };
  
const fetchDataYield = async (waterBodyId) => {
  try {
    const cacheKey = `${campaignId}-${waterBodyId}-${filterUser}`;
    if (dataYieldCache[cacheKey] !== undefined) {
      console.log('DATAYIELD CACHEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE' + dataYieldCache[cacheKey]);
      return dataYieldCache[cacheKey];
    }

    const token = localStorage.getItem('token');
    let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
      ? `${entrypoint}/dashboard/yield_ByWaterBody/${campaignId}/${waterBodyId}/${filterUser}`
      : `${entrypoint}/dashboard/yield_ByWaterBody/${campaignId}/${waterBodyId}/null`;
      console.log('DATAYIELD' + dataYieldCache[cacheKey]);
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const responseData = await response.json();
    const data = responseData.yield;
    
    // Mise en cache des données
    setDataYieldCache(prevCache => ({
      ...prevCache,
      [cacheKey]: data
    }));

    return data;

  } catch (error) {
    console.error("Erreur lors de la récupération des données :", error);
  }
};

const fetchFishingData = async (waterBodyId) => {
  try {
    const cacheKey = `${campaignId}-${waterBodyId}-${filterUser}`;
    if (fishingDataCache[cacheKey] !== undefined) {
      return fishingDataCache[cacheKey];
    }

    const token = localStorage.getItem('token');
    let filterUserValid = filterUser != null && !isNaN(filterUser);
    let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
      ? `${entrypoint}/dashboard/fishing_data/${campaignId}/${waterBodyId}/${filterUser}`
      : `${entrypoint}/dashboard/fishing_data/${campaignId}/${waterBodyId}/null`;

    const response = await fetch(
      url,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    const responseData = await response.json();
    const yieldNet = responseData.yield_net;

    // Mise en cache des données
    setFishingDataCache(prevCache => ({
      ...prevCache,
      [cacheKey]: yieldNet
    }));

    return yieldNet;

  } catch (error) {
    console.error("Erreur lors de la récupération des données :", error);
  }
};


const fetchDataGrowthCoeffTotal = async (waterBodyId) => {
  try {
    const cacheKey = `${campaignId}-${waterBodyId}-${filterUser}`;
    if (growthCoeffCache[cacheKey] !== undefined) {
      return growthCoeffCache[cacheKey];
    }

    const token = localStorage.getItem('token');
    let filterUserValid = filterUser != null && !isNaN(filterUser);
    let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
      ? `${entrypoint}/dashboard/coeff_accroissement/${campaignId}/${waterBodyId}/${filterUser}`
      : `${entrypoint}/dashboard/coeff_accroissement/${campaignId}/${waterBodyId}/null`;

    const response = await fetch(
      url,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    const responseData = await response.json();
    const data = responseData.coefficient_accroissement;

    // Mise en cache des données
    setGrowthCoeffCache(prevCache => ({
      ...prevCache,
      [cacheKey]: data
    }));

    return data;

  } catch (error) {
    console.error("Erreur lors de la récupération des données :", error);
  }
};

const fetchDataRecipe = async (waterBodyId) => {
  try {
    const cacheKey = `${campaignId}-${waterBodyId}-${filterUser}`;
    if (recipeCache[cacheKey] !== undefined) {
      return recipeCache[cacheKey];
    }

    const token = localStorage.getItem('token');
    let filterUserValid = filterUser != null && !isNaN(filterUser);
    let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
      ?  `${entrypoint}/dashboard/gross_profits/${campaignId}/${waterBodyId}/null/${filterUser}`
      : `${entrypoint}/dashboard/gross_profits/${campaignId}/${waterBodyId}/null/null`;

    const response = await fetch(
      url,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    const responseData = await response.json();
    const data = responseData.data;

    // Mise en cache des données
    setRecipeCache(prevCache => ({
      ...prevCache,
      [cacheKey]: data
    }));

    return data;

  } catch (error) {
    console.error("Erreur lors de la récupération des données :", error);
  }
};

const fetchDataAveragePrice = async (waterBodyId) => {
  try {
    const cacheKey = `${campaignId}-${waterBodyId}-${filterUser}`;
    if (averagePriceCache[cacheKey] !== undefined) {
      return averagePriceCache[cacheKey];
    }

    const token = localStorage.getItem('token');
    let filterUserValid = filterUser != null && !isNaN(filterUser);
    let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
      ? `${entrypoint}/dashboard/average_price/${campaignId}/${waterBodyId}/${filterUser}`
      : `${entrypoint}/dashboard/average_price/${campaignId}/${waterBodyId}/null`;

    const response = await fetch(
      url,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    const responseData = await response.json();
    const averagePrice = responseData.average_price;

    // Mise en cache des données
    setAveragePriceCache(prevCache => ({
      ...prevCache,
      [cacheKey]: averagePrice
    }));

    return averagePrice;

  } catch (error) {
    console.error("Erreur lors de la récupération des données :", error);
  }
};

const fetchDataSumCharges = async (waterBodyId) => {
  try {
    const cacheKey = `${campaignId}-${waterBodyId}-${filterUser}`;
    if (sumChargesCache[cacheKey] !== undefined) {
      console.log('SUM CHARGES DDDDDDDDDDDDDDDDDDDDDDDDDD');
      return sumChargesCache[cacheKey];
    }

    const token = localStorage.getItem('token');
    let filterUserValid = filterUser != null && !isNaN(filterUser);
    let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
      ?  `${entrypoint}/dashboard/sum_charges/${campaignId}/${waterBodyId}/null/${filterUser}`
      : `${entrypoint}/dashboard/sum_charges/${campaignId}/${waterBodyId}/null/null`;
    console.log('SUM CHARGES' + sumChargesCache[cacheKey]);
    const response = await fetch(
      url,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    const responseData = await response.json();
    const data = responseData.data;

    // Mise en cache des données
    setSumChargesCache(prevCache => ({
      ...prevCache,
      [cacheKey]: data
    }));

    return data;

  } catch (error) {
    console.error("Erreur lors de la récupération des données :", error);
  }
};

const fetchDataGrossMargins = async (waterBodyId) => {
  try {
      const cacheKey = `${campaignId}-${waterBodyId}-${filterUser}`;
      if (grossMarginsCache[cacheKey] !== undefined) {
        return grossMarginsCache[cacheKey];
      }
      const token = localStorage.getItem('token');
      let filterUserValid = filterUser != null && !isNaN(filterUser);
      let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
      ?  `${entrypoint}/dashboard/gross_margins/${campaignId}/${waterBodyId}/null/${filterUser}`
      : `${entrypoint}/dashboard/gross_margins/${campaignId}/${waterBodyId}/null/null`;
      const response = await fetch(
          url,
          {
              headers: {
                  'Authorization': `Bearer ${token}`,
              }
          }
      );
      const responseData = await response.json();
      const data = responseData.data;
      setGrossMarginsCache(prevCache => ({
        ...prevCache,
        [cacheKey]: data
      }));
      return data;
  } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
  }
};
const fetchLevelIndicator = async () => {
  try {
      const cacheKey = `${campaignId}-${filterUser}`;
      if (dataCache[cacheKey] !== undefined) {
        setLevelIndicator(dataCache[cacheKey]);
        return;
      }
      const token = localStorage.getItem('token');
      let filterUserValid = filterUser != null && !isNaN(filterUser);
      let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
      ? `${entrypoint}/dashboard/level_indicator/${campaignId}/${filterUser}`
      : `${entrypoint}/dashboard/level_indicator/${campaignId}/null`;
      const response = await fetch(
          url,
          {
              headers: {
                  'Authorization': `Bearer ${token}`,
              }
          }
      );
      const responseData = await response.json();
      const data = responseData.data;
      setDataCache(prevCache => ({
        ...prevCache,
        [cacheKey]: data
      }));
      setLevelIndicator(data);

  } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
  }
};
const fetchDataAll = async (fetchData, setData) => {
  const waterBodyDetails = await fetchWaterBodyIds();
  const data = {};

  for (const detail of waterBodyDetails) {
      let fetchedData = await fetchData(detail.id);
      data[detail.id] = fetchedData;
  }

  for (const key in data) {
      if (data.hasOwnProperty(key) && Array.isArray(data[key]) && data[key].length === 0) {
          data[key] = 0;
      }
  }

  setData(data);
};

  useEffect(() => {
    fetchWaterBodyIds();
    fetchDataAll(fetchFishStockingPerCategory, setFishStocking);
    fetchDataAll(fetchDataYield, setYieldWB);
    fetchDataAll(fetchFishingData, setFishingData);
    fetchDataAll(fetchDataGrowthCoeffTotal, setGrowthCoeff)
    fetchDataAll(fetchDataRecipe, setGrossProfits)
    fetchDataAll(fetchDataAveragePrice, setAveragePrice)
    fetchDataAll(fetchDataSumCharges, setSumCharges)
    fetchDataAll(fetchDataGrossMargins, setGrossMargins)
    fetchLevelIndicator();
  }, [filterUser, filterCampaign1, waterBodyList]);
  const calculateAverage = (data, key = null, grossProfitsData = {}, sumChargesData = {}) => {
    let sum = 0;
    let count = 0;

    for (const id of Object.keys(data)) {
        const value = data[id];

        // Si c'est grossMargins, vérifier grossProfits et sumCharges
        if (key === 'grossMargins') {
          const grossProfitsValue = grossProfitsData[id] > 0 && (grossProfitsData[id] !== null && grossProfitsData[id] !== undefined) ? grossProfitsData[id] : '_';
          const sumChargesValue = sumChargesData[id] > 0 && (sumChargesData[id] !== null && sumChargesData[id] !== undefined) ? sumChargesData[id] : '_';
            // Si les deux valeurs sont zéro, alors ignorer cette entrée
            if (grossProfitsValue !== '_' && sumChargesValue !== '_') {
                sum += parseFloat(value);
                count++;
            }

        }

        if (value !== null && value !== undefined && value != 0 && key !== 'grossMargins') {
            sum += parseFloat(value);
            count++;
        }
    }

    return count === 0 ? "_" : (sum / count).toFixed(2);
};
  return (

    <div className="backgroundBalance">
      <p className="section-title">Comparaisons avec le groupe d'exploitant de même niveau technique</p>
      <div className="background-container container-fluid">
        <div className="chart-container row">
            <div style={{ marginBottom: '50px' }}>
                <CustomTable
                  verticalHeader={true}
                  bold={true}
                  columns={[
                    { key: 'indicator', label: '' },
                    ...waterBodies.map(wb => ({ key: wb.name.replace(/\s+/g, ''), label: wb.name })),
                    { key: 'moyenneSelectionExploitation', label: 'Moyenne selection exploitation', bold:true},
                    { key: 'resultatGroupeExploitant', label: 'Résultat du groupe d\'exploitant du même niveau', bold:true},
                  ]}

                  data={[
                    { id: 1, indicator: 'Rendement brut (kg/ha)', ...waterBodies.reduce((acc, wb) => {
                      acc[wb.name.replace(/\s+/g, '')] = yieldWB[wb.id] > 0 && (yieldWB[wb.id] !== null && yieldWB[wb.id] !== undefined) ? yieldWB[wb.id] : '_';
                      return acc;
                    }, {}),
                    moyenneSelectionExploitation: calculateAverage(yieldWB),
                    resultatGroupeExploitant: levelIndicator.brutYield ?? '_'},
                    { id: 2, indicator: 'Empoissonnage (kg/ha)', ...waterBodies.reduce((acc, wb) => {
                      acc[wb.name.replace(/\s+/g, '')] = fishStocking[wb.id] || '_';
                      return acc;
                    }, {}),
                    moyenneSelectionExploitation: calculateAverage(fishStocking),
                    resultatGroupeExploitant: levelIndicator.fishStocking ?? '_' },
                    { id: 3, indicator: 'Rendement net (kg/ha)', ...waterBodies.reduce((acc, wb) => {
                      acc[wb.name.replace(/\s+/g, '')] = (fishingData[wb.id] > 0 && fishingData[wb.id] !== null && fishingData[wb.id] !== undefined) ? fishingData[wb.id] : '_';
                      return acc;
                    }, {}),
                    moyenneSelectionExploitation: calculateAverage(fishingData),
                    resultatGroupeExploitant: levelIndicator.netYield ?? '_' },
                    { id: 4, indicator: 'Coefficient d\'accroissement', ...waterBodies.reduce((acc, wb) => {
                      acc[wb.name.replace(/\s+/g, '')] = growthCoeff[wb.id] || '_';
                      return acc;
                    }, {}),
                    moyenneSelectionExploitation: calculateAverage(growthCoeff),
                    resultatGroupeExploitant: levelIndicator.growthCoefficient ?? '_' },
                    { id: 5, indicator: 'Recette (€/ha)', ...waterBodies.reduce((acc, wb) => {
                      acc[wb.name.replace(/\s+/g, '')] = grossProfits[wb.id] > 0 && (grossProfits[wb.id] !== null && grossProfits[wb.id] !== undefined) ? grossProfits[wb.id] : '_';
                      return acc;
                    }, {}),
                    moyenneSelectionExploitation: calculateAverage(grossProfits),
                    resultatGroupeExploitant: levelIndicator.profits ?? '_' },
                    { id: 6, indicator: 'Prix moyen de vente (€/kg)', ...waterBodies.reduce((acc, wb) => {
                      acc[wb.name.replace(/\s+/g, '')] = averagePrice[wb.id] || '_';
                      return acc;
                    }, {}),
                    moyenneSelectionExploitation: calculateAverage(averagePrice),
                    resultatGroupeExploitant: levelIndicator.averageSellingPrice ?? '_' },
                    { id: 7, indicator: 'Charge (€/ha)', ...waterBodies.reduce((acc, wb) => {
                      acc[wb.name.replace(/\s+/g, '')] = sumCharges[wb.id] > 0 && (sumCharges[wb.id] !== null && sumCharges[wb.id] !== undefined) ? sumCharges[wb.id] : '_';
                      return acc;
                    }, {}),
                    moyenneSelectionExploitation: calculateAverage(sumCharges),
                    resultatGroupeExploitant: levelIndicator.charges ?? '_' },
                    {
                      id: 8,
                      indicator: 'Marge brute (€/ha)',
                      ...waterBodies.reduce((acc, wb) => {
                        const grossProfitsValue = grossProfits[wb.id] > 0 && (grossProfits[wb.id] !== null && grossProfits[wb.id] !== undefined) ? grossProfits[wb.id] : '_';
                        const sumChargesValue = sumCharges[wb.id] > 0 && (sumCharges[wb.id] !== null && sumCharges[wb.id] !== undefined) ? sumCharges[wb.id] : '_';
                        if (grossProfitsValue === '_' && sumChargesValue === '_') {
                          acc[wb.name.replace(/\s+/g, '')] = '_';
                        } else {
                          acc[wb.name.replace(/\s+/g, '')] = (grossMargins[wb.id] !== null && grossMargins[wb.id] !== undefined) ? grossMargins[wb.id] : '_';
                        }
                        return acc;
                      }, {}),
                      moyenneSelectionExploitation: calculateAverage(grossMargins, 'grossMargins', grossProfits, sumCharges),
                      resultatGroupeExploitant: levelIndicator.brutMargins ?? '_'
                    },
                  ]}
                />
            </div>
        </div>
      </div>
    </div>
  );
}

export default Checked;
