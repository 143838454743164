import React, { useState, useEffect, useRef } from "react";
import { Edit, FormDataConsumer, EditBase,useDataProvider,AutocompleteInput ,useShowController, useRefresh, SaveButton, Toolbar, useListController, useRedirect,useEditController, useGetOne, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import isEqual from 'lodash/isEqual';
import { entrypoint } from "../config/index.js";
import GrantFormEdit from "../Tools/GrantForm/GrantFormEdit.js";


const GrantRequestEdit = (props) => {
  const { record, isLoading } = useEditController();
  const redirect = useRedirect();
  const [grantData, setGrantData] = useState(null);
  const userId = record?.user;
  const grantYearId = record?.grantYear;
  const grantRequest = record?.id;
  const prevTransformedDataRef = useRef();

  const { data: grantContents } = useGetList('grant_form_contents', {
    sort: { field: 'grant_form_action_id', order: 'ASC' },
    filter: { user: userId, grantYear: grantYearId, grantRequest: grantRequest }
  });


  const actionIds = [...new Set(grantContents?.map(content => content.grantFormAction))];
  const { data: grantActions } = useGetList('grant_form_actions', {
    filters: { id_in: actionIds.join(",") }
  });

  const transformedData = grantContents?.map(content => {
    const action = grantActions?.find(action => action.id === content.grantFormAction);
      return {
          ...content,
          grantFormAction: {
              id: action?.id,
              action: action?.action,
              parent_id: action?.parentId
          }
      };
  });



  const [reRenderKey, setReRenderKey] = useState(0);
  const [hasShownComplianceForm, setHasShownComplianceForm] = useState(false);
  const varData = transformedData;
  console.log(hasShownComplianceForm);
  useEffect(() => {
    if (!isEqual(transformedData, prevTransformedDataRef.current)) {
      prevTransformedDataRef.current = transformedData;
      if (!hasShownComplianceForm) {
        setHasShownComplianceForm(true);
      }
      setReRenderKey((prevKey) => prevKey + 1);

    }
  }, [transformedData]);

  const refresh = useRefresh();
  const sendGrantRequest = (response) => {
    let userIdRaw = response?.user;
      let userIdSuccess = userIdRaw?.split('/')[2];
      let grantYearIdSuccess = response?.grantYear;
      let grantRequestIdFinal = response?.id?.split('/')[2];
      let oldUserIdSplit = userId?.split('/')[2];
      const url = `${entrypoint}/grantrequest/grant-form-content-edit/${userIdSuccess}/${grantYearIdSuccess}/${grantYearId}/${grantIndex}/${grantRequestIdFinal}/${oldUserIdSplit}`;
      const token = localStorage.getItem('token');
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(grantData !== null ? grantData : transformedData)
        })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                console.error(`Erreur : ${data.error}`);
                reject(data.error);
            } else {
                console.log(data.message);
                console.log(data.mailSent);
                resolve();
            }
        })
        .catch(error => {
            console.error('Une erreur est survenue lors de l\'envoi des données:', error);
            reject(error);
        });
    });
};

  const onSuccess = (response) => {

    sendGrantRequest(response)
    .then(() => {

        redirect('/grant_requests');
        refresh();
    })
    .catch((error) => {
        console.error("La requête a échoué:", error);
    });
  };
  const choicesUser = [
    { role: "ROLE_FISHFARMER", name: formatRole("ROLE_FISHFARMER") },
    { role: "ROLE_TRANSFORMER", name: formatRole("ROLE_TRANSFORMER") },
    { role: "ROLE_COLLECTOR", name: formatRole("ROLE_COLLECTOR") },
    { role: "ROLE_ENCLOSER", name: formatRole("ROLE_ENCLOSER") },
  ];

// Définir la hiérarchie des rôles
const roleHierarchy = [
  'ROLE_FISHFARMER', // Pisciculteur
  'ROLE_ENCLOSER',   // Ecloseur
  'ROLE_COLLECTOR',  // Collecteur
  'ROLE_TRANSFORMER',// Transformateur
  'ROLE_OTHERS',     // Autres
  'ROLE_RESTORER',   // Restaurateur
  'ROLE_VOLUNTEER',   // Bénévole
  'ROLE_USER',
  'ROLE_ADMIN'
];

function getHighestRole(roles) {
  // Trier les rôles du tableau selon la hiérarchie définie
  const sortedRoles = roles.sort((a, b) => {
    return roleHierarchy.indexOf(a) - roleHierarchy.indexOf(b);
  });

  // Retourner le rôle le plus élevé dans la hiérarchie
  return sortedRoles[0];
}

function formatRole(role) {
  // Si role est un tableau, trouver le rôle le plus élevé
  if (Array.isArray(role)) {
    role = getHighestRole(role);
  }
  return formatSingleRole(role);
}

// Fonction auxiliaire pour formater un seul rôle
function formatSingleRole(singleRole) {
  const withoutPrefix = singleRole.replace('ROLE_', '');

  switch (withoutPrefix) {
    case 'FISHFARMER':
      return 'Pisciculteur';
    case 'ENCLOSER':
      return 'Ecloseur';
    case 'COLLECTOR':
      return 'Collecteur';
    case 'TRANSFORMER':
      return 'Transformateur';
    case 'OTHERS':
      return 'Autres';
    case 'RESTORER':
      return 'Restaurateur';
    case 'VOLUNTEER':
      return 'Bénévole';
    default:
      return withoutPrefix;
  }
}
  const [selectedRole, setSelectedRole] = useState(record.role);

  const { data: filteredUsers } = useGetList(
    'users',
    {
      sort: { field: 'firstName', order: 'ASC' },
      // filter: { roles: selectedRole ? [selectedRole] : [] },
    },
    // { enabled: !!selectedRole }
);

  const userChoices = filteredUsers
    ? Object.values(filteredUsers).map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`
      }))
    : [];
    const [initialGrantData, setInitialGrantData] = useState(grantData);
  const [saveButtonAlwaysEnable, setSaveButtonAlwaysEnable] = useState(false);

  useEffect(() => {
    if (grantData !== initialGrantData) {
      setSaveButtonAlwaysEnable(true);
    } else {
      setSaveButtonAlwaysEnable(false);
    }
  }, [grantData, initialGrantData]);
  const [selectedUserId, setSelectedUserId] = useState(record?.user);
  const [selectedGrantYear, setSelectedGrantYear] = useState(record?.grantYear);
  const [grantIndex, setGrantIndex] = useState('');
  const dataProvider = useDataProvider();
  const handleUserChange = async (userId) => {
    const selectedUser = userChoices.find(user => user.id === userId);
    if (selectedUser) {
      setSelectedUserId(userId);
      setSelectedRole(formatRole(selectedUser.roles));
    }
  };
const handleGrantYearChange = (grantYear) => {
  setSelectedGrantYear(grantYear);
}
  useEffect(() => {
    if (selectedGrantYear && selectedUserId) {
        const fetchGrantIndex = async () => {
            try {
                const { data, total } = await dataProvider.getList('grant_requests', {
                    sort: { field: 'grantIndex', order: 'DESC' },
                    pagination: { page: 1, perPage: 1 },
                    filter: { user: selectedUserId, grantYear: selectedGrantYear }
                });
                console.log(data);
                const filteredData = data.filter(grant => grant.id !== record?.id);
                if (filteredData.length > 0) {
                  const highestIndex = parseInt(filteredData[0].grantIndex, 10);
                  setGrantIndex(String(highestIndex + 1));
              } else {
                  setGrantIndex("1");
              }
            } catch (error) {
                console.error('Erreur lors de la récupération de grantrequests:', error);
            }
        };

        fetchGrantIndex();
    }
}, [selectedGrantYear, selectedUserId, dataProvider]);

  const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton alwaysEnable={saveButtonAlwaysEnable} />
    </Toolbar>
  );
  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  const prepareYearChoices = () => {
    const currentYear = getCurrentYear()-5;
    const choices = [];

    for (let i = 0; i <= 10; i++) {
      const year = currentYear + i;
      choices.push({ id: year.toString(), name: year.toString() });
    }
    return choices;
  };
  const YearSelectInput = props => {
    return <SelectInput {...props} choices={prepareYearChoices()} />;
  };

  return (
      <Edit mutationOptions={{ onSuccess }}  mutationMode="pessimistic" {...record}>
        <SimpleForm sanitizeEmptyValues toolbar={<CustomToolbar />}>
          <h1 className={"titleName"}>{record !== undefined ? 'Demande de subventions' : ''}</h1>
          <Grid container className={"containerInput"}>
            <Grid item md={6} xs={12}>
              <YearSelectInput source="grantYear" label="Année de subvention" onChange={(e) => handleGrantYearChange(e.target.value)} fullWidth/>
            </Grid>
          </Grid>

          <TextInput
          source="role"
          defaultValue={selectedRole}
          className={"hidden"}
          type={"hidden"}
          fullWidth
        />
        <Grid container>
            <Grid item xs={6}>
                {selectedRole &&
                    <AutocompleteInput
                    label="Utilisateur"
                    source="user"
                    choices={userChoices}
                    onChange={handleUserChange}
                    fullWidth
                />
                }
            </Grid>
        </Grid>

        {hasShownComplianceForm && <GrantFormEdit key={reRenderKey} actionsData={transformedData} onUpdateData={setGrantData} />}
        <Grid container>
          <Grid item xs={6}>
            <TextInput label="Type de sous-dossier" source="directoryType" fullWidth multiline  />
          </Grid>
        </Grid>
        </SimpleForm>
      </Edit>
  );
}
export default GrantRequestEdit;
