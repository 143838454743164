import { Layout, useGetIdentity, useGetOne, useRecordContext } from "react-admin";
import { useLocation } from 'react-router-dom';
import BackButton from '../Tools/BackButton/BackButton';
import BackActionsButton from '../Tools/BackButton/BackActionsButton';
import { ReactQueryDevtools } from 'react-query/devtools';
import { MyAppBar } from '../MyAppBar';
import React from 'react';
import { Button } from 'react-admin';


const CustomLayout = (props) => {

  const location = useLocation();
  const search = location.search;
  const pathname = location.pathname;
  
  console.log(search);

  const hiddenNavbarRoutes = [
      "/forgot_password",
      "/forgot_password/",
      "/change_password",
      "/change_password/"
  ];

  const shouldHideNavbar = () => {
      return hiddenNavbarRoutes.includes(pathname);
  }

  if (shouldHideNavbar()) {
      return <div>{props.children}</div>;
  }

  // Vérifier si l'URL se termine par certaines routes spécifiques
  const isMenuPage = pathname.endsWith("/balance_sheet")
    + pathname.endsWith("/water_bodies")
    + pathname.endsWith("/custom-route")
    + pathname.endsWith("/water_bodies")
    + pathname.endsWith("/admin_manager")
    + pathname.endsWith("/news")
    + pathname.endsWith("/fish_prices");

  const shouldShowBackActionsButton = search.includes('?filter={%22waterBody%22:%22/water_bodies/');
  const isMultiRequesterPage = pathname.endsWith("/multi_requester");

  console.log('shouldShowBackActionsButton:', shouldShowBackActionsButton);

  const handleClearCache = () => {
      localStorage.removeItem("multiRequesterFormData");
      window.location.reload();
  };

  return (
    <Layout appBar={MyAppBar} {...props}>
      <ReactQueryDevtools />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{width:500}}>
          {!isMenuPage ? (<BackButton/>) : ('')}
          {shouldShowBackActionsButton ? (<BackActionsButton />) : ('')}
        </div>
        
        {isMultiRequesterPage && (
          <Button label="Réinitialiser la sélection" id="btnRetour" onClick={handleClearCache} style={{
            backgroundColor: "#4e73df",
            border: "none",
            width: 200,
            marginLeft: 'auto',
          }} />
        )}
      </div>
      {props.dashboard}
      {props.children}
    </Layout>
  );
};

export default CustomLayout;
