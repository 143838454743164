import * as React from 'react';
import { Create,TimeInput , useListController, useRedirect,minValue , useGetOne, useGetList, SimpleForm,DateTimeInput, TextInput, DateInput, required,number, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { RichTextInput } from 'ra-input-rich-text';

const AeratorCreate = () => {
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 5 },
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };
    });
  }
  let recordId;
  const queryParameters = new URLSearchParams(window.location.hash.split('?')[1])
  const id = queryParameters.get("id")
  const  waterBody   = useGetOne('water_bodies', { id: id });
  const onSuccess = () => {
    redirect({
      pathname: '/action_aerators',
      search: `filter=${JSON.stringify({ waterBody: id })}`,
    });
  };

  const validateUserCreation = (values) => {
    const errors = {};

    if (Date.parse(values.startDate) > Date.parse(values.endDate)){
      console.log("try");
      errors.endDate = 'La date de fin ne peut pas être inférieure à la date de début';
    }
    return errors
  }
  let current = useListController();
  return (
      <Create mutationOptions={{ onSuccess }}>
        <SimpleForm validate={validateUserCreation} sanitizeEmptyValues>
          <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput  source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <DateInput source="startDate" label="Date début" validate={[required()]} fullWidth />
            </Grid>
            <Grid item xs={3} className={"itemForm2"}>
              <TimeInput  source="startTime" label="Heure début" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <DateInput  source="endDate" label="Date fin" validate={[required()]}  fullWidth/>
            </Grid>
            <Grid item xs={3} className={"itemForm2"}>
              <TimeInput  source="endTime" label="Heure fin" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <ReferenceInput source="aerator"  reference="aerators">
                <SelectInput optionText="name" label="Type aerateur" validate={[required()]} fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="power" label="Puissance (en Kw)"  fullWidth />
            </Grid>
            <Grid item xs={3} className={"itemForm2"}>
              <NumberInput source="electricalCost" label="Coût du Kw.h (en € HT)"  fullWidth />
            </Grid>
          </Grid>
          <Grid container>
          <Grid item xs={6}>
            <TextInput source="note" label="Remarque(s)" multiline fullWidth />
          </Grid>
        </Grid>
          {waterBody.data !== undefined ? <TextInput source="waterBody" className={"hidden"} defaultValue={waterBody.data.id} type={"hidden"}/> : ''}

        </SimpleForm>
      </Create>
  );
}

export default AeratorCreate;
