import React, { useState, useEffect } from "react";
import "../index.css";
import { usePermissions } from 'react-admin';
import TinyBarChart from '../Tools/Charts/TinyBarChart';
import StackedTinyBarChart from '../Tools/Charts/StackedTinyBarChart';
import 'moment/locale/fr';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomTable from '../Tools/Charts/Table';
import { entrypoint } from "../config/index.js";

const CompareCampaign = ({filterCampaign1, filterCampaign2, filterUser}) => {
    let [sumCharges, setSumCharges] = useState([]);
    let [grossMargins, setGrossMargins] = useState([]);
    let [fishPerCategory, setFishPerCategory] = useState([]);
    let [profitsPerCategory, setProfitsPerCategory] = useState([]);
    let [compareWB, setCompareWB] = useState([]);
    let campaignId = '';
    let campaignId2 = '';
    const { permissions } = usePermissions();
    const speciesColors = [
        "#8249F4", 
        "#BBF449", 
        "#4966F4",
        "#D749F4", 
        "#F449BB",
        "#FFC107", 
      ];
    if (filterCampaign1) {
        campaignId = filterCampaign1.split("/").pop();
    }
    if (filterCampaign2) {
        campaignId2 = filterCampaign2.split("/").pop();
    }
    
    const fetchDataCharges = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
                ? `${entrypoint}/dashboard/sum_charges/${campaignId}/null/${campaignId2}/${filterUser}`
                : `${entrypoint}/dashboard/sum_charges/${campaignId}/null/${campaignId2}/null`;
    
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
    
            const responseData = await response.json();
            const data = responseData.charges_per_campaign;
            setSumCharges(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    
    const fetchDataMargins = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
                ? `${entrypoint}/dashboard/gross_margins/${campaignId}/null/${campaignId2}/${filterUser}`
                : `${entrypoint}/dashboard/gross_margins/${campaignId}/null/${campaignId2}/null`;
    
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
    
            const responseData = await response.json();
            const data = responseData.data;
            setGrossMargins(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    
    const fetchFishingPerCategory = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
                ? `${entrypoint}/dashboard/total_fishing_ByWaterBody/${campaignId}/null/${campaignId2}/${filterUser}`
                : `${entrypoint}/dashboard/total_fishing_ByWaterBody/${campaignId}/null/${campaignId2}/null`;
    
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
    
            const responseData = await response.json();
            const data = responseData.data;
            setFishPerCategory(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    
    const fetchDataProfitsPerCategory = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
                ? `${entrypoint}/dashboard/gross_profits/${campaignId}/null/${campaignId2}/${filterUser}`
                : `${entrypoint}/dashboard/gross_profits/${campaignId}/null/${campaignId2}/null`;
    
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
    
            const responseData = await response.json();
            const data = responseData.profits_per_campaign;
            setProfitsPerCategory(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    
    const fetchDataCompareWaterBody = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
                ? `${entrypoint}/dashboard/compare_totalWaterSurface/${campaignId}/${campaignId2}/${filterUser}`
                : `${entrypoint}/dashboard/compare_totalWaterSurface/${campaignId}/${campaignId2}/null`;
    
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
    
            const responseData = await response.json();
            const data = responseData.total_water_surface;
            setCompareWB(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    
    useEffect(() => {
        fetchDataCharges();
        fetchDataMargins();
        fetchFishingPerCategory();
        fetchDataProfitsPerCategory();
        fetchDataCompareWaterBody();
    }, [filterCampaign1, filterCampaign2, filterUser]); 
    const dataCharges = sumCharges ? sumCharges.map((item, index) => ({
        name: `${item.campaign_date}`,
        charges: `${parseFloat(item.charges)}`,
        fill: speciesColors[index % speciesColors.length],
      })) : [];
    const dataMargins = grossMargins ? grossMargins.map((item, index) => ({
        name: `${item.campaign_date}`,
        margins: `${parseFloat(item.margins_campaign)}`,
        fill: speciesColors[index % speciesColors.length],
      })) : [];
      
    const getMaxWeight = (data) => {
        let weightsByCampaign = {};
    
        data.forEach(datum => {
            if (datum.fish_perCategory.length > 0) {
                if (!weightsByCampaign[datum.campaign_date]) {
                    weightsByCampaign[datum.campaign_date] = 0;
                }
                datum.fish_perCategory.forEach(category => {
                    weightsByCampaign[datum.campaign_date] += category.weight;
                });
            }
        });
    
        let maxWeight = 0;
        for (let campaign in weightsByCampaign) {
            if (weightsByCampaign[campaign] > maxWeight) {
                maxWeight = weightsByCampaign[campaign];
            }
        }
    
        return Math.round(maxWeight * 100) / 100;
    }
    
    const getMaxMargin = (data) => {
        let marginsByCampaign = {};
    
        data.forEach(datum => {
            if (!marginsByCampaign[datum.campaign_date]) {
                marginsByCampaign[datum.campaign_date] = 0;
            }
            marginsByCampaign[datum.campaign_date] += datum.margins_campaign;
        });
    
        let maxMargin = 0;
        let maxAbsMargin = 0;
        for (let campaign in marginsByCampaign) {
            if (marginsByCampaign[campaign] > maxMargin) {
                maxMargin = marginsByCampaign[campaign];
            }
            if (Math.abs(marginsByCampaign[campaign]) > Math.abs(maxAbsMargin)) {
                maxAbsMargin = marginsByCampaign[campaign];
            }
        }
    
        return { maxMargin: Math.round(maxMargin * 100) / 100, maxAbsMargin: Math.round(maxAbsMargin * 100) / 100 };
    }
    const findMaxProfits = (data) => {
        let maxProfits = 0;
    
        data.forEach(item => {
            const profits = parseFloat(item.profits_campaign);
            if (profits > maxProfits) {
                maxProfits = profits;
            }
        });
    
        return Math.round(maxProfits * 100) / 100;
    };
    
    let { maxMargin, maxAbsMargin } = getMaxMargin(grossMargins);
    let maxWeight = getMaxWeight(fishPerCategory);
    let maxCharge = Math.max(...sumCharges.map(item => parseFloat(item.charges)));
    let maxProfits = findMaxProfits(profitsPerCategory);
    maxCharge = Math.round(maxCharge * 100) / 100
    const roundToNextMagnitude = (value) => {
        const magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(value))));
        const roundedValue = Math.ceil(Math.abs(value) / magnitude) * magnitude;
        return value >= 0 ? roundedValue : -roundedValue;
    };
    
    // Now, apply the roundToNextMagnitude function to each value
    const roundedMaxMargin = roundToNextMagnitude(maxMargin);
    const roundedMaxAbsMargin = roundToNextMagnitude(maxAbsMargin);
    const roundedMaxWeight = roundToNextMagnitude(maxWeight);
    const roundedMaxCharge = roundToNextMagnitude(maxCharge);
    const roundedMaxProfits = roundToNextMagnitude(maxProfits);
    
    return (

        <div className="backgroundBalance">
            <p className="section-title">Comparaisons entre campagnes</p>
            <div className="background-container container-fluid">
            <div style={{padding:12, width:'98%', marginBottom:40}}>
                <CustomTable
                
                    columns={[
                        { key: 'campaign_date', label: 'Campagne' },
                        { key: 'surface', label: 'Surface pêchée' },
                    ]}
                    
                    data={compareWB}
                    verticalHeader={true}
                />
            </div>
                <div className="chart-container row">
                    <div className='flextab'>
                        <div style={{ marginBottom: 50 }}>
                        <TinyBarChart
                            title="Charges de l'activité étangs sur l'exploitation (en € HT)"
                            ticks={[0, roundedMaxCharge]}
                            data={dataCharges}
                            unityValue='€ HT'
                        />
                        </div>
                        <div style={{ marginBottom: 50 }}>
                        <TinyBarChart
                            title="Marge brute de l'activité étangs sur l'exploitation (en € HT)"
                            ticks={maxAbsMargin < 0 ? [0, roundedMaxMargin, roundedMaxAbsMargin] : [0, roundedMaxAbsMargin]}
                            data={dataMargins}
                            unityValue='€ HT'
                        />

                        </div>
                    </div>
                </div>
            </div>
            <p className="section-title">Bilan d'exploitation sur plusieurs campagnes</p>
            <div className="background-container container-fluid">
                <div className="chart-container row">
                    <div className='flextab'>
                        <div style={{ marginBottom: 50 }}>
                        <StackedTinyBarChart
                            title="Volumes annuels de poissons produits par espèce (en kg)"
                            ticks={[0, roundedMaxWeight]}
                            rawData={fishPerCategory}
                            unityValue='kg'
                            categoryPropertyName="fish_perCategory"
                            valuePropertyName="weight" 
                        />
                        </div>

                        <div style={{ marginBottom: 50 }}>
                        <StackedTinyBarChart
                            title="Recettes annuelles par espèce de poisson (en € HT)"
                            ticks={[0, roundedMaxProfits]}
                            rawData={profitsPerCategory}
                            unityValue='€ HT'
                            categoryPropertyName="recipePerCategory"
                            valuePropertyName="recipe" 
                        />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CompareCampaign;
