import React, { useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Cell, Tooltip, XAxis, YAxis } from 'recharts';
import JumboDemoCard from "../../@jumbo/components/JumboDemoCard";

const TinyBarChart = ({ title, ticks, legend, data, size, unityValue, perPage }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const allKeys = [];
  const legendEntries = [];
  Object.values(data).forEach((values) => {
    Object.keys(values).forEach((key) => {
      if (key !== 'name' && key !== 'fill' && !key.startsWith('fill_') && allKeys.indexOf(key) === -1) {
        allKeys.push(key);
      }
    });

    allKeys.forEach((key) => {
      legendEntries.push({ name: `${values.name}`, fill: values[`fill_${key}`] || values.fill });
    });
  });

  const dataArray = Object.values(data).map((values, index) => {
    const entry = { name: values.name, fill: values.fill };
    Object.keys(values)
      .filter((key) => key !== 'name' && key !== 'fill')
      .forEach((key) => {
        entry[key] = values[key];
        if (values[`fill_${key}`]) {
          entry[`fill_${key}`] = values[`fill_${key}`];
        }
      });
    return entry;
  });

  const formatNumber = (number) => {
    return parseFloat(number).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    });
  };

  const formatTick = (value) => {
    const absValue = Math.abs(value);
    if (absValue >= 1000 && absValue < 1000000) {
      return (value < 0 ? "-" : "") + (absValue / 1000).toLocaleString('en') + 'k';
    }
    if (absValue >= 1000000) {
      return (value < 0 ? "-" : "") + (absValue / 1000000).toLocaleString('en') + 'M';
    }
    return (value < 0 ? "-" : "") + value.toLocaleString('en');
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" transform="rotate(0)">
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomLegend = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        {legendEntries.map((entry, index) => (
          <div key={`legend-${index}`} style={{ display: 'flex', alignItems: 'center', margin: "30px 5px 5px 10px", position: 'relative' }}>
            {legend !== 'other' && <div style={{ width: 10, height: 10, backgroundColor: entry.fill, marginRight: 5 }}></div>}
            <span style={{ fontSize: '12px', transform: legend === 'other' ? 'rotate(-45deg)' : 'none', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {entry.name.replace(/\s*\([^)]*\)\s*/g, '')}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, title, unityValue }) => {
    if (active && payload && payload.length) {
      const newTitle = title ? title.replace(/\s*\([^)]*\)\s*|\/ha\/étang|\/étang/g, '') : '';
      return (
        <div style={{ backgroundColor: 'white', borderRadius: '5px', border: '1px solid darkgray', padding: 15, width: '80%' }}>
          <p style={{ fontWeight: 'bold', marginBottom: 10 }}>
            <span>{payload[0].payload.name}</span>
          </p>
          {payload.map((entry, index) => (
            <p key={`tooltip-${index}`}>
              <span style={{ wordWrap: 'break-word', color: payload[index].payload[`fill_${entry.dataKey}`] || payload[index].payload.fill }}>
                {`${newTitle} : ${formatNumber(entry.value)} ${unityValue ? unityValue : ''}`}
              </span>
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const itemsPerPage = perPage || dataArray.length;
  const totalPages = Math.ceil(dataArray.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  const paginatedData = dataArray.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const shouldDisplayLegend = dataArray.length <= 10;

  return (
    <JumboDemoCard
      title={
        <span style={{ fontSize: '18px' }}>
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{title}</div>
        </span>
      }
      wrapperSx={{ pt: 0, backgroundColor: 'background.paper', display:'flex', flexDirection:'column'}}
    >
      <ResponsiveContainer width="100%" height={size ? size : 300}>
        <BarChart data={paginatedData} margin={{ top: 15, right: 0, left: 0, bottom: legend === 'other' ? 30 : 0 }}>
          <YAxis ticks={[0, ...ticks]} tickFormatter={formatTick} axisLine={false} />
          {allKeys.length > 1 && <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />}
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip cursor={false} content={<CustomTooltip title={title} unityValue={unityValue} />} />

          {allKeys.length <= 1 && shouldDisplayLegend && <Legend content={<CustomLegend />} />}
          {allKeys.map((key) => (
            <Bar key={`bar-${key}`} dataKey={key} name={key}>
              {paginatedData.map((entry, index) => (
                <Cell key={`cell-${key}-${index}`} fill={entry[`fill_${key}`] || entry.fill} />
              ))}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
      {perPage && (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            {Array.from({ length: totalPages }).map((_, pageIndex) => (
              <span
                key={pageIndex}
                style={{
                  display: 'inline-block',
                  width: 10,
                  height: 10,
                  margin: '0 5px',
                  borderRadius: '50%',
                  backgroundColor: currentPage === pageIndex ? '#4e73df' : '#ccc'
                }}
              ></span>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <button
              onClick={handlePrevPage}
              style={{
                backgroundColor: '#4e73df',
                color: 'white',
                border: 'none',
                padding: '5px 10px',
                borderRadius: '3px',
                cursor: 'pointer',
                margin: '0 5px',
                width:'100px'
              }}
            >
              Précédent
            </button>
            <button
              onClick={handleNextPage}
              style={{
                backgroundColor: '#4e73df',
                color: 'white',
                border: 'none',
                padding: '5px 10px',
                borderRadius: '3px',
                cursor: 'pointer',
                margin: '0 5px',
                width:'100px'
              }}
            >
              Suivant
            </button>
          </div>
        </>
      )}
    </JumboDemoCard>
  );
};

export default TinyBarChart;
