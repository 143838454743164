import React, { useState, useEffect } from "react";
import "../index.css";
import { usePermissions } from 'react-admin';
import equals from "../img/equals.svg";
import TinyBarChart from '../Tools/Charts/TinyBarChart';
import CustomTable from '../Tools/Charts/Table';
import StackedBarChart from '../Tools/Charts/BarChartStacked';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import 'moment/locale/fr';
import { entrypoint } from "../config/index.js";

const GlobalStats = ({ filterCampaign1, filterUser }) => {
    const [isTableVisible3, setTableVisible3] = useState(false);
    const [fishData, setFishData] = useState([]);
    let [yieldCategory, setYieldCategory] = useState([]);
    let [grossProfits, setGrossProfits] = useState([]);
    let [sumCharges, setSumCharges] = useState([]);
    let [sumChargesWB, setSumChargesWB] = useState([]);
    let [grossMarginsWB, setGrossMarginsWB] = useState([]);
    let [yieldWB, setYieldWB] = useState([]);
    let [totalFishStockingWB, setTotalFishStockingWB] = useState([]);

    let [grossMargins, setGrossMargins] = useState([]);
    let [tabFishStockings, setTabFishStockings] = useState([]);
    let [tabFertilization, setTabFertilization] = useState([]);
    let [tabFood, setTabFood] = useState([]);
    let [tabLiming, setTabLiming] = useState([]);
    let [tabOthers, setTabOthers] = useState([]);
    // let [WBids, setWBids] = useState(null);
    const [waterBodies, setWaterBodies] = useState([]);
    const [waterBodyColors, setWaterBodyColors] = useState({});
    const { permissions } = usePermissions();
    let campaignId = '';
    if (filterCampaign1) {
        campaignId = filterCampaign1.split("/").pop();
    }
    const colors = ["#8249F4", "#BBF449", "#4966F4", "#D749F4", "#F449BB", "#F48249"];

    const fetchWaterBodyIds = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${entrypoint}/water_bodies?user=${filterUser}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            
            const responseData = await response.json();
            const waterBodies = responseData['hydra:member'];
            
            const waterBodyDetails = waterBodies.map((waterBody) => {
                return { id: waterBody.id, waterSurface: waterBody.waterSurface };
            });

            const waterBodyColors = {};
            waterBodies.forEach((waterBody, index) => {
                const colorIndex = index % colors.length;
                waterBodyColors[waterBody.id] = colors[colorIndex];
            });
            setWaterBodies(waterBodies); 
            setWaterBodyColors(waterBodyColors);
            let WBids = waterBodyDetails.map(item => item.id);
           
            WBids = WBids.join(",");
           
            // setWBids(ids);
            
            return {WBids, waterBodyDetails};
        } catch (error) {
            console.error("Erreur lors de la récupération des IDs des étangs :", error);
            return [];
        }
    };
    const fetchDataCategory = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/sum_totalWeight_bySpeciesCategory/${campaignId}/null/${filterUser}`
            : `${entrypoint}/dashboard/sum_totalWeight_bySpeciesCategory/${campaignId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.data;
            const dataArray = Object.entries(data).map(([speciesCategory, totalWeight]) => ({ speciesCategory, totalWeight }));
            setFishData(dataArray);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataYieldCategory = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/average_gross_yield/${campaignId}/${filterUser}`
            : `${entrypoint}/dashboard/average_gross_yield/${campaignId}/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.rendement_brut_moyen;
            setYieldCategory(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataProfits = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/gross_profits/${campaignId}/null/null/${filterUser}`
            : `${entrypoint}/dashboard/gross_profits/${campaignId}/null/null/null`;

            const response = await fetch(url
                ,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.data;
            setGrossProfits(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataCharges = async (WBids) => {
        try {
            const token = localStorage.getItem('token');
            console.log(WBids);
            const hasMultipleIds = WBids && WBids.includes(",");
            let url = `${entrypoint}/dashboard/sum_charges/${campaignId}`;

            if (WBids && !(permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))) {
                url += `/${WBids}/null/null`;
            }
            else if (WBids && (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))){
                url += `/${WBids}/null/${filterUser}`;
            }
            else if (!WBids && (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))) {
                url += `/null/null/${filterUser}`;
            }
            else {
                url += `/null/null/null`;
            }


            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            console.log("WBids:", WBids);
            console.log("hasMultipleIds:", hasMultipleIds);

            const responseData = await response.json();
            
            if (!WBids) {
                const data = responseData.data
                setSumCharges(data);
                console.log('DDDDDDDDDDDDDDDDDDD');
            } else if (!hasMultipleIds) {
                const data = responseData.data
                setSumChargesWB({ [WBids]: data });
            } else if (hasMultipleIds) {
                const data = {};
                const dataAll = responseData.allWaterBody;
                WBids.split(',').forEach((wbId, index) => {
                    data[wbId] = dataAll[index].data;
                    console.log("EEEEEEEEEEEEEEEE" + data[wbId])
                });
                
                setSumChargesWB(data);
                
            }
            
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataMargins = async (WBids) => {
        try {
            const token = localStorage.getItem('token');
            const hasMultipleIds = WBids && WBids.includes(",");
            console.log(WBids);
            let url = `${entrypoint}/dashboard/gross_margins/${campaignId}`;

            if (WBids && !(permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))) {
                url += `/${WBids}/null/null`;
            }
            else if (WBids && (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))){
                url += `/${WBids}/null/${filterUser}`;
            }
            else if (!WBids && (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))) {
                url += `/null/null/${filterUser}`;
            }
            else {
                url += `/null/null/null`;
            }

            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            const responseData = await response.json();
            if (!WBids) {
                setGrossMargins(responseData.data);
            } else if (!hasMultipleIds) {
                setGrossMarginsWB({ [WBids]: responseData.data });
            } else if (hasMultipleIds) {
                const data = {};
                WBids.split(',').forEach((wbId, index) => {
                    data[wbId] = responseData.allWaterBody[index].data;
                });
                setGrossMarginsWB(data);
                console.log(grossMarginsWB);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataYield = async (WBids) => {
        try {
            const token = localStorage.getItem('token');
            const hasMultipleIds = WBids && WBids.includes(",");
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/yield_ByWaterBody/${campaignId}/${WBids}/${filterUser}`
            : `${entrypoint}/dashboard/yield_ByWaterBody/${campaignId}/${WBids}/null`;

            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            const responseData = await response.json();
            if (!hasMultipleIds) {
                setYieldWB({ [WBids]: responseData.yield });
            } else if (hasMultipleIds) {
                const data = {};
                WBids.split(',').forEach((wbId, index) => {
                    data[wbId] = responseData.allWaterBody[index].yield;
                });
                setYieldWB(data);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataTotalFishStockingWB = async (WBids) => {
        try {
            const token = localStorage.getItem('token');
            const hasMultipleIds = WBids && WBids.includes(",");
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/total_fish_stocking_ByWaterBody/${campaignId}/${WBids}/null/${filterUser}`
            : `${entrypoint}/dashboard/total_fish_stocking_ByWaterBody/${campaignId}/${WBids}/null/null`;


            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            const responseData = await response.json();
            if (!hasMultipleIds) {
                setTotalFishStockingWB({ [WBids]: responseData.fishstockingwb });
            } else if (hasMultipleIds) {
                const data = {};
                WBids.split(',').forEach((wbId, index) => {
                    data[wbId] = responseData.allWaterBody[index].fishstockingwb;
                });
                setTotalFishStockingWB(data);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };


    const fetchDataAll = async (fetchData, setData) => {
        const {ids, waterBodyDetails} = await fetchWaterBodyIds();
        const data = {};

        for (const detail of waterBodyDetails) {
            let fetchedData = await fetchData(detail.id);
            data[detail.id] = fetchedData;
        }

        for (const key in data) {
            if (data.hasOwnProperty(key) && Array.isArray(data[key]) && data[key].length === 0) {
                data[key] = 0;
            }
        }

        setData(data);
    };

    const fetchDataTabFishStockings = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/tab_fishStocking_global/${campaignId}/null/${filterUser}`
            : `${entrypoint}/dashboard/tab_fishStocking_global/${campaignId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const stock = responseData.cout_total_stock;
            const achats = responseData.cout_total_achats;
            const total = responseData.cout_total;
            setTabFishStockings({ stock, achats, total });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataTabFertilization = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/tab_fertilization_global/${campaignId}/null/${filterUser}`
            : `${entrypoint}/dashboard/tab_fertilization_global/${campaignId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.fertilizers;
            const totaldata = responseData.fertilizers_totalcost;
            setTabFertilization({ data, totaldata });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataTabFood = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/tab_food_global/${campaignId}/null/${filterUser}`
            : `${entrypoint}/dashboard/tab_food_global/${campaignId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.food;
            const totaldata = responseData.food_totalcost;
            setTabFood({ data, totaldata });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataTabLiming = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/tab_liming_global/${campaignId}/null/${filterUser}`
            : `${entrypoint}/dashboard/tab_liming_global/${campaignId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const data = responseData.liming;
            const totaldata = responseData.liming_totalcost;
            setTabLiming({ data, totaldata });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    const fetchDataTabOthers = async () => {
        try {
            const token = localStorage.getItem('token');
            let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser != null && !isNaN(filterUser))
            ? `${entrypoint}/dashboard/tab_other_global/${campaignId}/null/${filterUser}`
            : `${entrypoint}/dashboard/tab_other_global/${campaignId}/null/null`;
            const response = await fetch(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            const responseData = await response.json();
            const aeration = responseData.aeration;
            const pumpingCost = responseData.pumping_cost;
            const totaldata = responseData.total_cost;
            setTabOthers({ aeration, pumpingCost, totaldata });
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    const formatNumber = (number) => {
        const parsedNumber = parseFloat(number);
        if (Number.isInteger(parsedNumber)) {
            return parsedNumber.toString();
        }

        return parsedNumber.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        });
    };

    const firstInfo = fishData
        .map((item) => {
            let category = item.speciesCategory.toLowerCase();
            if (category === "autres") {
            category = "autres espèces";
            }
            const prefix = category[0].match(/[aeiouy]/i) ? "d'" : "de ";
            const detail = prefix + category;
            return {
            info: parseFloat(item.totalWeight),
            detail: detail,
            };
        })
        .sort((a, b) => {
            return b.info - a.info;
    });
    const sumOfInfo = firstInfo.reduce((sum, item) => sum + item.info, 0);


    useEffect(() => {
        (async () => {
            
            fetchDataCategory();
            fetchDataProfits();
            fetchDataCharges();
            fetchDataMargins();
            fetchDataTabFishStockings();
            fetchDataTabFertilization();
            fetchDataTabFood();
            fetchDataTabLiming();
            fetchDataTabOthers();
            fetchDataYieldCategory();
            const {WBids, waterBodyDetails} = await fetchWaterBodyIds();
            fetchDataCharges(WBids);
            fetchDataMargins(WBids);
            fetchDataYield(WBids);
            fetchDataTotalFishStockingWB(WBids);
            // fetchDataAll(fetchDataCharges, setSumChargesWB);
            // fetchDataAll(fetchDataMargins, setGrossMarginsWB);
            // fetchDataAll(fetchDataYield, setYieldWB);
            // fetchDataAll(fetchDataTotalFishStockingWB, setTotalFishStockingWB);
        })();
    }, [filterUser]);
    

    const valuesChargesWB = Object.values(sumChargesWB);
    const maxChargesWB = Math.max(...valuesChargesWB);

    const valuesGrossMarginsWB = Object.values(grossMarginsWB);
    const maxGrossMarginsWB = Math.max(...valuesGrossMarginsWB);

    const valuesYieldWB = Object.values(yieldWB);
    const maxYieldWB = Math.max(...valuesYieldWB);

    const valuesTotalStockingWB = Object.values(totalFishStockingWB);
    const maxtotalFishStockingWB = Math.max(...valuesTotalStockingWB);

    const roundToNextMagnitude = (value) => {
        const magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(value))));
        const roundedValue = Math.ceil(Math.abs(value) / magnitude) * magnitude;
        return value >= 0 ? roundedValue : -roundedValue;
    };
    const roundedGrossProfits = roundToNextMagnitude(grossProfits);
    const roundedSumCharges = roundToNextMagnitude(sumCharges);
    const roundedGrossMargins = roundToNextMagnitude(grossMargins);
    const roundedSumChargesWB = roundToNextMagnitude(maxChargesWB);
    const roundedGrossMarginsWB = roundToNextMagnitude(maxGrossMarginsWB);
    const roundedYieldWB = roundToNextMagnitude(maxYieldWB);
    const roundedTotalFishStockingWB = roundToNextMagnitude(maxtotalFishStockingWB);

    const findMaxAbsoluteValue = (dataObj) => {
        const values = Object.values(dataObj);
        let maxAbsoluteValue = values[0];  // initialize with the first value

        values.forEach(value => {
            if (Math.abs(value) > Math.abs(maxAbsoluteValue)) {
                maxAbsoluteValue = value;
            }
        });

        return maxAbsoluteValue;
    };

    const maxAbsoluteValue = findMaxAbsoluteValue(grossMarginsWB);
    const roundedMaxAbsoluteValue = roundToNextMagnitude(maxAbsoluteValue);
    function customSort(a, b) {
        const nameA = a.name;
        const nameB = b.name;

        return nameA.localeCompare(nameB);
    }


    return (

        <div className="backgroundBalance">
            <div className="background-container container-fluid">
            <div className="chart-container-center flextab2" style={{margin:'10px 0 20px 0'}}>
                <div className="divInfoCenter">
                    <p className="pInfoWater" style={{color:'#4e73df', fontSize:25}}>{sumOfInfo ? `${sumOfInfo.toFixed(0)} kg` : '0 kg'}</p>
                    <p className="pInfoWater2">pêchés au total</p>
                </div>
                <div className="divInfoFish">
                    <p className="pInfoWater" style={{color:'#4e73df', fontSize:25}}>{yieldCategory ? `${yieldCategory} kg/ha` : '0 kg/ha'}</p>
                    <p className="pInfoWater2">rendement brut moyen</p>
                </div>
            </div>
                <p style={{ fontSize: 22, fontWeight: 'bold', marginBottom: 50 }}>Poids pêchés par catégories d'espèces</p>
                <div className="chart-container row">

                    {firstInfo.map((item, index) => (
                        <div className="divInfoFish" key={index}>
                            <p className="pInfoWater">{item.info.toFixed(0)} kg</p>
                            <p className="pInfoWater2">{item.detail}</p>
                        </div>
                    ))}
                </div>
            </div>
            <p className="section-title">Indicateurs économiques</p>
            <div className="background-container container-fluid">
                <div className="chart-container row indic">
                    <div className="chartspe">
                        <div className="chartspediv">
                            <StackedBarChart
                                title={[
                                    `Recettes (en € HT) :`,
                                    <span style={{ color: '#4CAF50' }}>{`${parseFloat(grossProfits).toFixed(0)} €`}</span>
                                ]}
                                legend="none"
                                ticks={[0, roundedGrossProfits]}
                                data={[{ name: 'Recettes', valeur: grossProfits, fill: '#4966F4' }]}
                                unityValue='€ HT'
                            />
                        </div>
                        <div style={{ marginLeft: '20px', marginRight: '20px', alignSelf: 'center' }} className="chart-iconspe">
                            <FontAwesomeIcon icon={faCircleMinus} size="xl" />
                        </div>
                        <div className="chartspediv">
                            <StackedBarChart
                                title={[
                                    `Charges (en € HT) :`,
                                    <span style={{ color: '#B00020' }}>{`${parseFloat(sumCharges).toFixed(0)} €`}</span>
                                ]}
                                legend="none"
                                ticks={[0, roundedSumCharges]}
                                data={[{ name: 'Charges', valeur: sumCharges, fill: '#F48249' }]}
                                unityValue='€ HT'
                            />
                            <p className="invisibleInfo" onClick={() => setTableVisible3(!isTableVisible3)}>
                                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faCircleInfo} />
                                Visualiser le détail des charges de l'exploitation
                            </p>
                        </div>
                        <div className="order-icon chart-iconspe" style={{ marginLeft: '20px', marginRight: '20px', alignSelf: 'center' }}>
                            <img src={equals} style={{ width: 40, pointerEvents: 'none' }} className="equals" />
                        </div>
                        <div className="chartspediv order-chart">
                            <StackedBarChart
                                title={[
                                    `Marge brute (en € HT) :`,
                                    <span style={{ color: (`${grossMargins}` >= 0) ? '#4CAF50' : '#B00020' }}>{`${parseFloat(grossMargins).toFixed(0)} €`}</span>
                                ]}
                                legend="none"
                                ticks={[0, roundedGrossMargins]}
                                data={[{ name: 'Marge brute', valeur: grossMargins, fill: (`${grossMargins}` >= 0) ? '#4CAF50' : '#B00020'  }]}
                                unityValue='€ HT'
                            />
                        </div>

                    </div>

                    {isTableVisible3 && (
                        <div className="order-tab">
                            <p className="pTitleLeft"><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCircleInfo} />  Détail des charges de l'exploitation</p>

                            <div className="chart-container">
                                <div className="divInfoGlobal_2 flextab">
                                    <CustomTable
                                        columns={[
                                            { key: 'fishstocking', label: 'Empoisonnage' },
                                            { key: 'totalcost', label: 'Coût (en € HT)' },
                                        ]}
                                        data={[
                                            { id: 1, fishstocking: 'Stock', totalcost: `${tabFishStockings.stock} €` },
                                            { id: 2, fishstocking: 'Achats de poissons', totalcost: `${tabFishStockings.achats} €` },
                                            { id: 3, fishstocking: 'Total', totalcost: `${tabFishStockings.total} €` },
                                        ]}
                                    />
                                    <CustomTable
                                        columns={[
                                            { key: 'fertilization', label: 'Fertilisation' },
                                            { key: 'quantity', label: 'Quantité' },
                                            { key: 'totalcost', label: 'Coût (en € HT)' },
                                        ]}
                                        data={tabFertilization.data && tabFertilization.data.length > 0 ? (
                                            tabFertilization.data.map((item, index) => ({
                                                id: index + 1,
                                                fertilization: item.name,
                                                quantity: item.quantity,
                                                totalcost: `${item.cost} €`,
                                            })).concat({
                                                id: tabFertilization.data.length + 1,
                                                fertilization: 'Total',
                                                quantity: '',
                                                totalcost: `${tabFertilization.totaldata} €`,
                                            })
                                        ) : [
                                            {
                                                id: 1,
                                                fertilization: 'Total',
                                                quantity: '',
                                                totalcost: `${tabFertilization.totaldata} €`,
                                            }
                                        ]}
                                    />

                                    <CustomTable
                                        columns={[
                                            { key: 'otherinputs', label: 'Autres intrants' },
                                            { key: 'totalcost', label: 'Coût (en € HT)' },
                                        ]}
                                        data={tabOthers.aeration && tabOthers.aeration.length > 0 ? (
                                            tabOthers.aeration.map((item, index) => ({
                                                id: index + 1,
                                                otherinputs: item.name,
                                                totalcost: `${item.cost} €`,
                                            })).concat({
                                                id: tabOthers.aeration.length + 1,
                                                otherinputs: 'Pompage',
                                                totalcost: `${tabOthers.pumpingCost} €`,
                                            }).concat({
                                                id: tabOthers.aeration.length + 2,
                                                otherinputs: 'Total',
                                                totalcost: `${tabOthers.totaldata} €`,
                                            })
                                        ) : [
                                            {
                                                id: 1,
                                                otherinputs: 'Pompage',
                                                totalcost: `${tabOthers.pumpingCost} €`,
                                            },
                                            {
                                                id: 2,
                                                otherinputs: 'Total',
                                                totalcost: `${tabOthers.totaldata} €`,
                                            }
                                        ]}

                                    />
                                    <CustomTable
                                        columns={[
                                            { key: 'food', label: 'Aliments' },
                                            { key: 'quantity', label: 'Quantité' },
                                            { key: 'totalcost', label: 'Coût (en € HT)' },
                                        ]}
                                        data={tabFood.data && tabFood.data.length > 0 ? (
                                            tabFood.data.map((item, index) => ({
                                                id: index + 1,
                                                food: item.name,
                                                quantity: item.quantity,
                                                totalcost: `${item.cost} €`,
                                            })).concat({
                                                id: tabFood.data.length + 1,
                                                food: 'Total',
                                                quantity: '',
                                                totalcost: `${tabFood.totaldata} €`,
                                            })
                                        ) : [
                                            {
                                                id: 1,
                                                food: 'Total',
                                                quantity: '',
                                                totalcost: `${tabFood.totaldata} €`,
                                            }
                                        ]}
                                    />

                                    <CustomTable
                                        columns={[
                                            { key: 'liming', label: 'Chaulage' },
                                            { key: 'quantity', label: 'Quantité (en T)' },
                                            { key: 'totalcost', label: 'Coût (en € HT)' },
                                        ]}
                                        data={tabLiming.data && tabLiming.data.length > 0 ? (
                                            tabLiming.data.map((item, index) => ({
                                                id: index + 1,
                                                liming: item.name,
                                                quantity: item.quantity,
                                                totalcost: `${item.cost} €`,
                                            })).concat({
                                                id: tabLiming.data.length + 1,
                                                liming: 'Total',
                                                quantity: '',
                                                totalcost: `${tabLiming.totaldata} €`,
                                            })
                                        ) : [
                                            {
                                                id: 1,
                                                liming: 'Total',
                                                quantity: '',
                                                totalcost: `${tabLiming.totaldata} €`,
                                            }
                                        ]}
                                    />

                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
            <p className="section-title">Indicateurs technico-économiques des étangs</p>
            <div className="background-container container-fluid">
                <div className="chart-container row">
                    <div className="divInfoGlobal_2 flextab">

                        <div style={{ marginBottom: 57 }}>
                        {sumChargesWB && (
                            <TinyBarChart
                            title="Charges/ha/étang (en € HT/ha)"
                            size={400}
                            ticks={[0, roundedSumChargesWB]}
                            unityValue="€ HT/ha"
                            perPage={4} // Ajout de la propriété perPage avec la valeur 8
                            data={waterBodies
                                .map((waterBody, index) => ({
                                name: waterBody?.name,
                                valeur: sumChargesWB[waterBody?.id] ?? 0,
                                fill: waterBodyColors[waterBody?.id]
                                }))
                                .sort(customSort)}
                            />
                        )}
                        </div>


                        <div style={{ marginBottom: 30 }}>
                            {grossMarginsWB && <TinyBarChart
                                title="Marge brute/ha/étang (en € HT/ha)"
                                size={400}
                                unityValue='€ HT/ha'
                                perPage={4} 
                                ticks={[0, roundedMaxAbsoluteValue >= 0 ? roundedGrossMarginsWB : roundedGrossMarginsWB, roundedMaxAbsoluteValue]}
                                data={waterBodies.map((waterBody, index) => ({
                                    name: waterBody?.name,
                                    valeur: grossMarginsWB[waterBody?.id] ?? 0,
                                    fill: waterBodyColors[waterBody?.id]
                                })).sort(customSort)}
                            />}

                        </div>
                        <div style={{ marginBottom: 57 }}>
                            {totalFishStockingWB && <TinyBarChart
                                title="Empoissonnage/étang (en kg/ha)"
                                size={400}
                                unityValue='kg/ha'
                                perPage={4} 
                                ticks={[0, roundedTotalFishStockingWB]}
                                data={waterBodies.map((waterBody, index) => ({
                                    name: waterBody?.name,
                                    valeur: totalFishStockingWB[waterBody?.id] ?? 0,
                                    fill: waterBodyColors[waterBody?.id]
                                })).sort(customSort)}
                            />}
                        </div>
                        <div style={{ marginBottom: 57 }}>
                            {yieldWB && <TinyBarChart
                                title="Rendement brut/ha/étang (en kg/ha)"
                                size={400}
                                unityValue='kg/ha'
                                perPage={4} 
                                ticks={[0, roundedYieldWB]}
                                data={waterBodies.map((waterBody, index) => ({
                                    name: waterBody?.name,
                                    valeur: yieldWB[waterBody?.id] ?? 0,
                                    fill: waterBodyColors[waterBody?.id]
                                })).sort(customSort)}
                            />}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default GlobalStats;
