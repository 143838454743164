import * as React from "react";
import {
  EditBase,
  useGetOne,
  FormDataConsumer,
  SelectArrayInput,
  PasswordInput,
  SimpleForm,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TextInput,
  SelectInput,
  required,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  ImageInput,
  ImageField,
  usePermissions,
  useDataProvider,
  useNotify,
  useRefresh,
  Button as RAButton
} from "react-admin";
import { useState, useEffect } from 'react';
import { Card, Grid } from "@mui/material";
import { useForm } from 'react-final-form';
import InputMask from 'react-input-mask';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '30%',
    maxHeight: 'calc(100% - 64px)',
  },
}));
const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

const prepareYearChoices = () => {
  const currentYear = getCurrentYear()-5;
  const choices = [];

  for (let i = 0; i <= 10; i++) {
    const year = currentYear + i;
    choices.push({ id: year.toString(), name: year.toString() });
  }
  console.log(choices);
  return choices;
};
const YearSelectInput = props => {
  return <SelectInput {...props} choices={prepareYearChoices()} />;
};
function GetIdContrib(id) {
  console.log(id);
  const  idContrib = useGetOne('member_ship_prices', { id: id });
  if(idContrib.data)  {
    return idContrib.data.originId;
  } else {
    return idContrib.data;
  }
}

const CollectorCreateDialog = ({ open, handleClose }) => {
  const [collectorName, setCollectorName] = React.useState("");
  const [collectorType, setCollectorType] = React.useState("");
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = async () => {
    try {
      const collectorType = 'Collecteur';
      const { data } = await dataProvider.create('collectors', {
        data: { name: collectorName, type: collectorType }
      });

      handleClose();
      notify('Collecteur crée avec succès', 'success');
      refresh();
    } catch (error) {
      notify('Error: ' + error.message, 'warning');
    }
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>Crée un collecteur</DialogTitle>
      <DialogContent sx={{ width: '100%' }}>
        {/* <Grid container>
          <Grid item xs={12}>
            <SelectInput
              source="collectorType"
              label="Type de collecteur"
              value={collectorType}
              onChange={e => setCollectorType(e.target.value)}
              choices={[
                { id: 'Collecteur', name: 'Collecteur' },
                { id: 'Technicien', name: 'Technicien' }
              ]}
              style={{width:'100%'}}
              fullWidth
            />
          </Grid>
        </Grid> */}
        <Grid container>
          <Grid item xs={12}>
            <TextInput
              source="collectorName"
              label="Nom du collecteur"
              value={collectorName}
              onChange={e => setCollectorName(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Valider
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

const CollectorInputWithAddButton = ({ handleOpenDialog }) => {
  return(
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ marginRight:30, width:400}}>
    <ReferenceInput source="collector" reference="collectors">
      <SelectInput optionText="name" label="Collecteur" validate={[required()]} fullWidth />
    </ReferenceInput>
    </div>
    <div style={{marginBottom:20}}>
      <RAButton onClick={handleOpenDialog} >
        <AddIcon/>
        <span style={{ marginRight: '1em', whiteSpace: 'nowrap', fontSize:'14px' }}>Créer un collecteur</span>
      </RAButton>
    </div>
  </div>
  )
};
 const UserEdit = () => {
   const { permissions } = usePermissions();
   const [openDialog, setOpenDialog] = React.useState(false);

   const handleOpenDialog = () => {
     setOpenDialog(true);
   };

   const handleCloseDialog = () => {
     setOpenDialog(false);
   };
   return(
       <EditBase redirect="show" sanitizeEmptyValues defaultValues={{
         password: '',
       }}>
         <div>
           <Card>
             <SimpleForm sanitizeEmptyValues>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <SelectInput source="civility" label="Civilité" choices={[
                     { id: 'Mme', name: 'Mme' },
                     { id: 'M', name: 'M' },
                   ]} validate={[required()]}/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="lastName" label="Nom" validate={[required()]} fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="firstName" label="Prénom" validate={[required()]} fullWidth/>
                 </Grid>
               </Grid>
               {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?

                   <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <SelectArrayInput source="roles" label="Rôles" choices={[
                     { id: 'ROLE_FISHFARMER', name: 'Pisciculteur' },
                     { id: 'ROLE_ADMIN', name: 'Administateur' },
                     { id: 'ROLE_ENCLOSER', name: 'Ecloseur' },
                     { id: 'ROLE_COLLECTOR', name: 'Collecteur' },
                     { id: 'ROLE_TRANSFORMER', name: 'Transformateur' },
                     { id: 'ROLE_OTHERS', name: 'Autres' },
                     { id: 'ROLE_RESTORER', name: 'Restaurateur' },
                     { id: 'ROLE_VOLUNTEER', name: 'Bénévole' },

                   ]} />
                 </Grid>
               </Grid>
                   : ''}
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <PasswordInput
                       format={v => null}
                       source="password"
                       label="Mot de passe"
                   />
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <ReferenceInput source="memberStatus"  reference="member_statuses">
                     <SelectInput  optionText="name" label="Statut de l'adhérent" validate={[required()]} fullWidth disabled={permissions.includes('ROLE_ADMIN') === false}  />
                   </ReferenceInput>
                 </Grid>
               </Grid>
               <FormDataConsumer>
                  {({ formData, ...rest }) => {
                      return formData.roles && formData.roles.includes('ROLE_COLLECTOR') && (
                          <Grid container className={"containerInput"}>
                              <Grid item md={6} xs={12}>
                                  <CollectorInputWithAddButton handleOpenDialog={handleOpenDialog} />
                              </Grid>
                          </Grid>
                      );
                  }}
              </FormDataConsumer>
              <CollectorCreateDialog open={openDialog} handleClose={handleCloseDialog} />
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="structureName" label="Entreprise/Organisme" fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="address" label="Adresse" validate={[required()]} fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="postcode" label="Code postal" validate={[required()]} fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="city" label="Ville" validate={[required()]} fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <BooleanInput source="sendsIdenticalContribution" label="Adresse envoi cotisation différente" validate={[required()]} fullWidth/>
                 </Grid>
               </Grid>

               <FormDataConsumer>
                 {({ formData, ...rest }) => formData.sendsIdenticalContribution === true &&


                     [
                       <Grid container className={"containerInput"}>
                         <Grid item md={6} xs={12}>
                           <TextInput source="structureName2" label="Entreprise/Organisme cotisation" fullWidth/>
                         </Grid>
                       </Grid>,
                       <Grid container className={"containerInput"}>
                         <Grid item md={6} xs={12}>
                           <TextInput source="contributionAddress" label="Adresse envoie cotisation" fullWidth/>
                         </Grid>
                       </Grid>,
                       <Grid container className={"containerInput"}>
                         <Grid item md={6} xs={12}>
                           <TextInput source="postCodeContribution" label="Code postal envoi cotisation" fullWidth/>
                         </Grid>
                       </Grid>,
                       <Grid container className={"containerInput"}>
                         <Grid item md={6} xs={12}>
                           <TextInput source="cityContribution" label="Ville envoi cotisation" fullWidth/>
                         </Grid>
                       </Grid>,


                     ]


                 }
               </FormDataConsumer>





               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="contactPerson" label="Personne à contacter"  fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="homePhone" label="Téléphone fixe"  fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="mobilePhone" label="Téléphone portable"  fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="email" label="Adresse email" validate={[required()]} fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="contactPerson2" label="Personne à contacter supplémentaire"  fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="homePhone2" label="Téléphone fixe"  fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="mobilePhone2" label="Téléphone portable"  fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="email2" label="Adresse email"  fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <NumberInput source="waterBodiesCount" label="Nombre d'étang"  fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <TextInput source="registeredArea" label="Surface ha cadastré" fullWidth/>
                 </Grid>
               </Grid>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <YearSelectInput source="currentYearMembership" label="Année d'adhésion" disabled={permissions.includes('ROLE_ADMIN') === false}/>
                 </Grid>
               </Grid>
               <FormDataConsumer>
                 {({formData, ...rest}) => formData.memberStatus && formData.currentYearMembership &&
                     <Grid container className={"containerInput"}>
                       <Grid item md={6} xs={12}>
                         <ReferenceInput source="memberShipPrice" reference={"member_ship_prices/?memberStatus="+GetIdContrib(formData.memberStatus)+"&memberShipYear="+formData.currentYearMembership}>
                           <SelectInput optionText="contribution" label="Cotisation (en € (HT))" validate={[required()]}
                                        fullWidth disabled={permissions.includes('ROLE_ADMIN') === false}/>
                         </ReferenceInput>
                       </Grid>
                     </Grid>
                 }
               </FormDataConsumer>
               <Grid container className={"containerInput"}>
                 <Grid item md={6} xs={12}>
                   <BooleanInput  source="paymentReceipts" label="Règlement reçu" validate={[required()]} fullWidth disabled={permissions.includes('ROLE_ADMIN') === false} />
                 </Grid>
               </Grid>
               {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?
                <>
                  <Grid container className={"containerInput"}>
                    <Grid item md={6} xs={12}>
                      <TextInput source="paymentDetail" label="Date et mode de règlement de l'adhésion" fullWidth/>
                    </Grid>
                  </Grid>
                  <Grid container className={"containerInput"}>
                    <Grid item md={6} xs={12}>
                      <BooleanInput source="membershipStatus" label="Statut de l'adhésion" validate={[required()]}
                                    fullWidth/>
                    </Grid>
                  </Grid>
                  <Grid container className={"containerInput"}>
                    <Grid item md={6} xs={12}>
                      <SelectInput source="technicalLevel" label="Niveau technique" optionValue="id" choices={[
                        { id: 1, name: '1' },
                        { id: 2, name: '2' },
                        { id: 3, name: '3' },
                      ]} />

                    </Grid>
                  </Grid>
                </>
                 : ''}


             </SimpleForm>
           </Card>
         </div>
       </EditBase>
   );
 }

export default UserEdit;
