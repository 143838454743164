import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    useShowController,
    Button,
    useGetOne,
    EditButton,
    TopToolbar,
    usePermissions
} from 'react-admin';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import ShortDescription from "../Tools/ShortDescription";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import { entrypoint } from "../config/index.js";

const ListImage = ({ data }) => {
    const { data: image, loading, error } = useGetOne('media_object', { id: data.file });
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading image</div>;
    if (!image) return null;

    return (
        <CardMedia
            component="div"
            sx={{
                width: 450,  // définir une largeur fixe
                height: 450, // définir une hauteur fixe
                backgroundImage: `url("${entrypoint}/images/${image.contentUrl}")`,

                backgroundSize: 'cover',
                backgroundPosition: 'center',
                margin: '0 auto',
                display: 'block',
            }}
        />
    );
};
const PostShowActions = () => {
    const {permissions} = usePermissions();

    return (<TopToolbar>
                    {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?

                        <EditButton />

                        : ''}
                </TopToolbar> );


}


const NewsShow = () => {
    const  data  = useShowController();
    return(
        <Show actions={<PostShowActions />}>

            <SimpleShowLayout>
                        <Card
                            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                        >
                            <ListImage data={data.record} />

                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                    style={{fontWeight: 'bold', fontSize: '1.5rem'}} // ou utilisez sx={{fontWeight: 'bold', fontSize: '1.5rem'}}
                                >
                                    {data.record.title}
                                </Typography>
                                <Typography>
                                    <ShortDescription description={data.record.content} maxLength={100000} />
                                </Typography>
                            </CardContent>
                        </Card>
    </SimpleShowLayout>
  </Show>
);}
export default NewsShow;
