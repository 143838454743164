import * as React from 'react';
import { Create, useRecordContext , useDataProvider, useCreate,  FormDataConsumer, useListController, useRedirect, useGetOne, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { RichTextInput } from 'ra-input-rich-text';
import 'moment/locale/fr';


const FishCreate = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 5 },
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
        {
          id: campaign.id,
          name: startDate+" / "+endDate
        };


    });
  }

  let recordId;
  const queryParameters = new URLSearchParams(window.location.hash.split('?')[1])
  const id = queryParameters.get("id")
  const  waterBody   = useGetOne('water_bodies', { id: id });
  const [create] = useCreate('action_fish_stockings');

  const onSuccess = (response) => {
    console.log(response);
    if (response.fishingState === "/fishing_states/1") {
        dataProvider.getOne('campaigns', { id: response.campaign })
            .then(campaignResponse => {
                const startDate = campaignResponse.data.startDate;
                return dataProvider.getList('campaigns', {
                    sort: { field: 'startDate', order: 'ASC' },
                    filter: { startDate: { $gt: startDate } },
                    pagination: { page: 1, perPage: 1 }
                });
            })
            .then(nextCampaignResponse => {
                console.log(nextCampaignResponse);
                const nextCampaign = nextCampaignResponse.data[1];

                const dataForStocking = {
                    campaign: nextCampaign.id,
                    date: response.date,
                    waterBody: response.waterBodyFishingState,
                    stockingOrigin: '/stocking_origins/1',
                    ... (response.collector !== null && { collector: response.collector }),
                    waterBodyStockingOrigin: id,
                    specieFish: response.specieFish ? response.specieFish : 'Stade non connu',
                    ... (response.numberHead !== null && { numberHead: response.numberHead }),
                    ... (response.averageWeight !== null && { averageWeight: response.averageWeight }),
                    totalWeight: response.totalWeight,
                    fishPrice: response.fishPrice
                };

                console.log("Données pour Empoissonnage:", dataForStocking);
                dataProvider.create('action_fish_stockings', { data: dataForStocking })
                .then(() => {
                    redirect({
                        pathname: '/action_fishes',
                        search: `filter=${JSON.stringify({ waterBody: id })}`,
                    });
                })
                .catch(error => {
                    console.error(error);
                });
            })
            .then(() => {
                redirect({
                    pathname: '/action_fish_stockings',
                    search: `filter=${JSON.stringify({ waterBody: id })}`,
                });
            })
            .catch(error => {
                console.error(error);
            });
    } else {
        // Si "fishingState" n'est pas "/fishing_states/2", vous redirigez immédiatement
        redirect({
            pathname: '/action_fishes',
            search: `filter=${JSON.stringify({ waterBody: id })}`,
        });
    }
};

  let current = useListController();
  let record = useRecordContext();

  function GetNameSpieces(id) {
    const  spieciesName = useGetOne('specie_fishes', { id: id });
    if(spieciesName.data)  {
      return spieciesName.data.name;
    } else {
      return spieciesName.data;
    }
  }
  function GetDefaultId(id) {
    const { data } = useGetList('specie_fishes/uniq');
    console.log(data);
    if (data !== undefined) {
      const test = data.filter(({name}) => name === GetNameSpieces(id));
    }


  }
  const formatSpecieFish = (record) => {
    if (!record.stage) {
      record.stage = "Stade non connu";
    }
    return record.stage;
  };

  const firstChoice = (formData) => {
    const datas = GetDefaultId(formData.specieFish)
    console.log("test",datas);


  };

  console.log(record);
  return(
    <Create  mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleForm sanitizeEmptyValues>
        <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
        <Grid container>
          <Grid item xs={6}>
            <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <ReferenceInput source="specieFish1"  reference="specie_fishes/uniq">
              <SelectInput  optionText="name" label="Espèce" validate={[required()]} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.specieFish1 &&
            <Grid container>
              <Grid item xs={6}>
                <ReferenceInput source="specieFish" sort={{ field: 'id', order: 'DESC' }} reference={"specie_fishes?name="+GetNameSpieces(formData.specieFish1)}>
                  <SelectInput optionText={formatSpecieFish}  label="Saisir stade espèce" validate={[required()]} fullWidth />
                </ReferenceInput>
              </Grid>
            </Grid>
          }
        </FormDataConsumer>

        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <NumberInput source="numberHead" label="Nombre de tête(s)" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} className={"itemForm1"}>
            <NumberInput source="averageWeight" label="Poids moyen (en kg)" fullWidth />
          </Grid>
          <Grid item xs={3} className={"itemForm2"}>
            <NumberInput source="totalWeight" label="Poids total (en kg)"  validate={[required()]} fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <ReferenceInput source="fishingState"  reference="fishing_states">
              <SelectInput optionText="name" label="Destination" validate={[required()]} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>

        <FormDataConsumer>
          {({ formData, ...rest }) => formData.fishingState === "/fishing_states/2" &&
              <Grid container>
                <Grid item xs={6}>
                  <ReferenceInput source="collector"  reference="collectors">
                    <SelectInput optionText="name" label="Collecteur"  fullWidth />
                  </ReferenceInput>
                </Grid>
              </Grid>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.fishingState === "/fishing_states/1"  &&
              <Grid container>
                <Grid item xs={6}>
                  <ReferenceInput source="waterBodyFishingState"  reference="water_bodies">
                    <SelectInput optionText="name" label="Liste étangs/bassins"  fullWidth />
                  </ReferenceInput>
                </Grid>
              </Grid>
          }
        </FormDataConsumer>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <DateInput  source="date" label="Date" validate={[required()]} fullWidth />
          </Grid>
        </Grid>

        <FormDataConsumer>
  {({ formData, ...rest }) => (
    <Grid container>
      <Grid item xs={12}>
      {formData.fishingState && formData.fishingState !== "/fishing_states/3" && (
        <Typography className={"titleName"} style={{ fontSize: '16px' }} gutterBottom>
          Si le tarif à saisir n'est pas présent dans la liste déroulante ci-dessous, merci de
          <Link to="/fish_prices"> cliquer ici</Link> pour l'ajouter.
        </Typography>
      )}
      </Grid>
      <Grid item xs={6}>

        {formData.fishingState === "/fishing_states/1" && ( // Gardé

          <ReferenceInput
            source="fishPrice"
            reference={"fish_prices?campaign=" + formData.campaign + "&specieFish=" + formData.specieFish}
          >
            <SelectInput optionText="costPrice" label="Tarif (en € HT / kg)" fullWidth />
          </ReferenceInput>
        )}
        {formData.fishingState === "/fishing_states/2" && ( // Vendu
          <ReferenceInput
            source="fishPrice"
            reference={"fish_prices?campaign=" + formData.campaign + "&specieFish=" + formData.specieFish}
          >
            <SelectInput optionText="disposalFeeCollector" label="Tarif (en € HT / kg)" fullWidth />
          </ReferenceInput>
        )}
        {formData.fishingState === "/fishing_states/4" && ( // Donné
          <ReferenceInput
            source="fishPrice"
            reference={"fish_prices?campaign=" + formData.campaign + "&specieFish=" + formData.specieFish}
          >
            <SelectInput optionText="costPrice" label="Tarif (en € HT / kg)" fullWidth />
          </ReferenceInput>
        )}
      </Grid>
    </Grid>
  )}


        </FormDataConsumer>
        <Grid container>
          <Grid item xs={6}>
            <TextInput source="note" label="Remarque(s)" multiline fullWidth />
          </Grid>
        </Grid>
        {waterBody.data !== undefined ? <TextInput source="waterBody" className={"hidden"} defaultValue={waterBody.data.id} type={"hidden"}/> : ''}
      </SimpleForm>
    </Create>
  );
}


export default FishCreate;
