import React, { useEffect, useState, useRef } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TextField } from '@mui/material';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function ComplianceFormEdit({ actionsData, onUpdateData }) {
    const [actions, setActions] = useState([]);
    const hasSetInitialActions = useRef(false);
    
    useEffect(() => {
        if (!hasSetInitialActions.current && actionsData && actionsData.length > 0) {
            setActions(actionsData);
            hasSetInitialActions.current = true;
        }
    }, [actionsData]);
    console.log(actions);
   
    const documentProofs = [
        "Présentation du registre d'étang consultation de l'espace adhérent",
        "Entretien visite avec l'exploitant"
    ];
    const mergeRules = [
        { start: 0, count: 5 },
        { start: 5, count: 11 }
    ];

    const getRowSpan = (index) => {
        for (let rule of mergeRules) {
            if (index === rule.start) return rule.count;
            if (index > rule.start && index < rule.start + rule.count) return 0;
        }
        return 1;
    };
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [currentActionIndex, setCurrentActionIndex] = useState(null);
    
    const handleOpenDialog = (index, content) => {
        setDialogContent(content);
        setCurrentActionIndex(index);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    let proofIndex = 0; 
    const handleActionChange = (index, field, value) => {
        const updatedActions = [...actions];
        updatedActions[index][field] = value;
        setActions(updatedActions);
        const dataToSend = updatedActions.map(({ action, ...rest }) => rest);
        console.log(dataToSend);
        onUpdateData(dataToSend);
    };
    
    return (
        <>
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            PaperProps={{ style: { minWidth: '300px' } }} 
        >
            <DialogTitle>{"Contenu"}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>

        <Table className="compliance-table">
            <TableHead>
                <TableRow>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '500px' }}>Actions</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '150px' }}>Documents preuve</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold' }}>Conforme: O/N</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '250px' }}>Points d'amélioration</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '250px' }}>Actions à conduire</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {actions && actions.length > 0 ? actions.map((action, index) => {
                    const rowSpan = getRowSpan(index);
                    let proofText = '';
                    if (rowSpan > 0) {
                        proofText = documentProofs[proofIndex] || '';
                        proofIndex++;
                    }
                    return (
                        <TableRow key={index}>
                            <TableCell>{action.complianceFormAction.action}</TableCell>
                            { 
                                rowSpan > 0 && 
                                <TableCell rowSpan={rowSpan} style={{ textAlign: 'center' }}>
                                    {proofText}
                                </TableCell>
                            } 
                            <TableCell style={{ textAlign: 'center' }}>
                            <Checkbox color="primary" checked={action.complianceBoolean === true} onChange={(e) => handleActionChange(index, 'complianceBoolean', e.target.checked)}/>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TextField 
                                    placeholder="Entrer les points d'amélioration" 
                                    variant="outlined" 
                                    size="small" 
                                    fullWidth 
                                    value={action.improvementPoints}
                                    onChange={(e) => handleActionChange(index, 'improvementPoints', e.target.value)}
                                    InputProps={{ 
                                        style: { fontSize: '12px' },
                                        endAdornment: (
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleOpenDialog(index, action.improvementPoints)}
                                            >
                                                <VisibilityIcon fontSize="small" />
                                            </IconButton>
                                        )
                                    }} 
                                />
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TextField 
                                    placeholder="Entrer les actions à conduire" 
                                    variant="outlined" 
                                    size="small" 
                                    fullWidth 
                                    value={action.actionToTake}
                                    onChange={(e) => handleActionChange(index, 'actionToTake', e.target.value)}
                                    InputProps={{ 
                                        style: { fontSize: '12px' },
                                        endAdornment: (
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleOpenDialog(index, action.actionToTake)}
                                            >
                                                <VisibilityIcon fontSize="small" />
                                            </IconButton>
                                        )
                                    }} 
                                />
                            </TableCell>
                        </TableRow>
                    );
                }): ''}
            </TableBody>
        </Table>
        </>
    );
}

export default ComplianceFormEdit;
