import * as React from 'react';
import { Edit, useListController, useEditController, useGetOne, useGetList, useRedirect, SimpleForm,DateTimeInput, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { RichTextInput } from 'ra-input-rich-text';

const FishingTeamEdit = () => {
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 5 },
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };
    });
  }
  const { record, isLoading } = useEditController();
  const onSuccess = () => {
    redirect({
      pathname: '/action_fishing_teams',
      search: `filter=${JSON.stringify({ waterBody: record.waterBody })}`,
    });
  };
  const  waterBody   = useGetOne('water_bodies', { id: record !== undefined ? record.waterBody : ''});
  let current = useListController();
  return (
      <Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic" sanitizeEmptyValues>
        <SimpleForm sanitizeEmptyValues>
          <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="fishingTime" label="Temps de pêche (en h)"  validate={[required()]} fullWidth />
            </Grid>
            <Grid item xs={3} className={"itemForm2"}>
              <NumberInput source="fishermenNumber" label="Nombre de pêcheurs" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="estimatedCost" label="Coût estimé (en € HT)" fullWidth />
            </Grid>
          </Grid>
          <Grid container>
          <Grid item xs={6}>
            <TextInput source="note" label="Remarque(s)" multiline fullWidth />
          </Grid>
        </Grid>
          <TextInput source="waterBody" className={"hidden"} type={"hidden"}/>
        </SimpleForm>
      </Edit>
  );
}

export default FishingTeamEdit;
