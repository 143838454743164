import React, { useState, useEffect } from 'react';
import { useDataProvider, ExportButton } from 'react-admin';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, TableContainer, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import { orderExporter, orderExporterPDF } from '../Tools/ExportExcel/Multirequester/exportMR';

const ViewResults = () => {
  const [data, setData] = useState([]);
  const [indicatorKeys, setIndicatorKeys] = useState([]);
  const dataProvider = useDataProvider();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const retrievedData = location.state?.data || [];
    
    // Calculate the correct 'marge brute / ha' for each item
    const calculatedData = retrievedData.map(item => {
      const surfaceArea = item.waterBody.waterSurface; // Surface area in hectares
      if (item.indicators['marge_brute']) {
        // Compute the gross margin per hectare
        item.indicators['marge_brute_ha'] = item.indicators['marge_brute'] / surfaceArea;
      }
      return item;
    });

    setData(calculatedData);

    // Collect unique indicator keys
    const keys = new Set();
    calculatedData.forEach(item => {
      Object.keys(item.indicators).forEach(key => keys.add(key));
    });
    setIndicatorKeys(Array.from(keys));
  }, [location.state]);

  let exportFileName = 'MR';

  const formatKey = (key) => {
    const customLabels = {
        'coeff_multiplicateur': 'Coefficient Multiplicateur',
        'marge_brute_ha': 'Marge Brut par Ha',
        'empoissonnage_kg_ha': 'Empoissonnage en Kg',
        'marges_brutes_kg_ha': 'Marge Brute par Kg',
        'rendements_net_kg_ha': 'Rendement Net en Kg/Ha',
        'coefficient_d_accroissement_globaux': 'Coefficient d’Accroissement Globaux',
        'rendement_brut_global': 'Rendement Brut Global',
        'coeff_accroissement_moyen': 'Coefficient d’Accroissement Moyen',
        'prix_moyen_vente': 'Prix Moyen de Vente',
        'empoissonnement_kg_ha': 'Empoissonnement en Kg/Ha',
        'protections_cormorans': 'Protections Cormorans',
        'dates_aeration': 'Dates d’Aération',
        'cout_pompage': 'Coût de Pompage',
        'temps_peche': 'Temps de Pêche',
        'travaux_entretien': 'Travaux d’Entretien',
        'donnees_regulations': 'Données de Régulation',
        'cout_fertilization': 'Coût de Fertilisation',
        'prix_chaulage': 'Prix du Chaulage',
        // Add more custom labels as needed
    };

    if (customLabels[key]) {
        return customLabels[key];
    }

    return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};


  const hasData = (key) => {
    return data.some(item => item[key] && item[key].length > 0);
  };

  const hasIndicatorData = () => {
    return indicatorKeys.length > 0;
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Résultats</h2>
      {data.length > 0 ? (
      <div style={{ marginBottom: 20, marginTop: 10, display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
        <Button variant="contained" color="primary" onClick={() => orderExporter(data, null, dataProvider, exportFileName)}>Exporter (Excel)</Button>
      </div>
      ) : (
        ''
      )}
      {data.length > 0 ? (
        <Paper>
          <TableContainer style={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Début Campagne</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Fin Campagne</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Nom Prénom</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Nom Étang</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Superficie Étang</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Ville</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Propriétaire Actuel</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Profondeur Moyenne</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Aérateur</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Électricité</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Nourrisseur</TableCell>
                  <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Distance</TableCell>
                  {hasIndicatorData() && indicatorKeys.map(key => (
                    <TableCell key={key} style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>{formatKey(key)}</TableCell>
                  ))}
                  {hasData('waterBody.protectionsCormorans') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Protections Cormorans</TableCell>}
                  {hasData('waterBody.datesAeration') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Dates Aération</TableCell>}
                  {hasData('waterBody.coutPompage') && <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Coût de Pompage</TableCell>}
                  {hasData('waterBody.tempsPeche') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Temps de Pêche</TableCell>}
                  {hasData('waterBody.travauxEntretien') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Travaux d'Entretien</TableCell>}
                  {hasData('waterBody.donneesRegulations') && <TableCell style={{ minWidth: 200, color: 'white', backgroundColor: '#4e73df' }}>Données Régulations</TableCell>}
                  {hasData('waterBody.coutFertilization') && <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Coût Fertilisation</TableCell>}
                  {hasData('waterBody.prixChaulage') && <TableCell style={{ minWidth: 150, color: 'white', backgroundColor: '#4e73df' }}>Prix Chaulage</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{moment(item.campaign.startDate).format('YYYY/MM/DD')}</TableCell>
                    <TableCell>{moment(item.campaign.endDate).format('YYYY/MM/DD')}</TableCell>
                    <TableCell>{item.waterBody.user.firstName || 'N/A'} {item.waterBody.user.lastName || 'N/A'}</TableCell>
                    <TableCell>{item.waterBody.name}</TableCell>
                    <TableCell>{item.waterBody.waterSurface}</TableCell>
                    <TableCell>{item.waterBody.town}</TableCell>
                    <TableCell>{item.waterBody.currentOwner || 'N/A'}</TableCell>
                    <TableCell>{item.waterBody.averageDepth || 'N/A'}</TableCell>
                    <TableCell>{item.waterBody.aerator ? 'Oui' : 'Non'}</TableCell>
                    <TableCell>{item.waterBody.electricity ? 'Oui' : 'Non'}</TableCell>
                    <TableCell>{item.waterBody.feeder ? 'Oui' : 'Non'}</TableCell>
                    <TableCell>{item.waterBody.distance || 'N/A'}</TableCell>
                    {hasIndicatorData() && indicatorKeys.map(key => (
                      <TableCell key={key}>
                        {item.indicators[key] && typeof item.indicators[key] === 'object'
                          ? (Array.isArray(item.indicators[key]) && item.indicators[key].length === 0)
                            ? 'N/A'  // Display 'N/A' if the array is empty
                            : Object.entries(item.indicators[key]).length === 0
                            ? 'N/A'  // Display 'N/A' if the object is empty
                            : Object.entries(item.indicators[key]).map(([subKey, value]) => (
                                <div key={subKey}>{`${subKey}: ${value}`}</div>
                              ))
                          : (item.indicators[key] || 'N/A')}
                      </TableCell>
                    ))}

                    {hasData('waterBody.protectionsCormorans') && (
                      <TableCell>
                        {item.waterBody.protectionsCormorans?.map(p => (
                          <div key={p.id}>{p.type} ({p.number})</div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                    {hasData('waterBody.datesAeration') && (
                      <TableCell>
                        {item.waterBody.datesAeration?.map(d => (
                          <div key={d.id}>
                            {`${moment(d.startDate).format('YYYY/MM/DD')} ${moment(d.startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(d.endDate).format('YYYY/MM/DD')} ${moment(d.endTime, 'HH:mm:ss').format('HH:mm')}`}
                          </div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                    {hasData('waterBody.coutPompage') && (
                      <TableCell>
                        {item.waterBody.coutPompage?.map(p => (
                          <div key={p.id}>{p.estimatedCost}</div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                    {hasData('waterBody.tempsPeche') && (
                      <TableCell>
                        {item.waterBody.tempsPeche?.map(p => (
                          <div key={p.id}>{`Pêcheurs: ${p.fishermenNumber}`}<br />{`Coût: ${p.estimatedCost}`}</div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                    {hasData('waterBody.travauxEntretien') && (
                      <TableCell>
                        {item.waterBody.travauxEntretien?.map(t => (
                          <div key={t.id}>{`Coût estimé: ${t.estimatedCost}`}</div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                    {hasData('waterBody.donneesRegulations') && (
                      <TableCell>
                        {item.waterBody.donneesRegulations?.map(r => (
                          <div key={r.id}>
                            {`Animal: ${r.regulatedAnimal.name}`}<br />
                            {`Vus: ${r.trappedNumber}`}<br />
                            {`Tirés: ${r.firedNumber}`}<br />
                            {`Piégés: ${r.killedNumber}`}
                          </div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                    {hasData('waterBody.coutFertilization') && (
                      <TableCell>
                        {item.waterBody.coutFertilization?.map(cf => (
                          <div key={cf.id}>{cf.estimatedCost}</div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                    {hasData('waterBody.prixChaulage') && (
                      <TableCell>
                        {item.waterBody.prixChaulage?.map(pc => (
                          <div key={pc.id}>{pc.purchasePrice * pc.quantity}</div>
                        )) || 'N/A'}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <p>Aucune donnée disponible</p>
      )}
    </div>
  );
};

export default ViewResults;
